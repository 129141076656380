import { Link } from "react-router-dom";
function App() {
  // <!-- External dependencies and styles -->
  // <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
  // <link rel="stylesheet" href="assets/css/style.css">
  // <link rel="stylesheet" href="assets/magnific-popup/magnific-popup.css">

  return <div>
  <button className="btn small orange">Small button</button>
  <button className="btn large grey">Large button</button>
  <button className="btn large transparent">Large transparent button</button>
  <button className="btn small back-button"><i className="back-cheveron" /></button>
  <div className="position-relative">
    <input type="text" placeholder="kjancbhj" required />
    <label htmlFor />
  </div>
  <div className="position-relative">
    <select className="select-box" id="select-box" required>
      <option value={0}>Select one option</option>
      <option value={0}>123456</option>
      <option value={0}>123456</option>
    </select>
    <label htmlFor="select-box" />
  </div>
  <div className="position-relative location-container">
    <input type="text" placeholder="Location" required id="location" />
    <button className="btn large orange pick-location"><i className="location-picker" /></button>
    <label htmlFor="location">degsrhdynhfg</label>
  </div>
  <div className="position-relative">
    <input type="file" placeholder required title id="select_files" />
    <label htmlFor="select_files" />
    <span>Upload file</span>
    <div className="uploads-preview popup-gallery">
      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt /> <i className="delete-icon" /></a>
      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt /> <i className="delete-icon" /></a>
      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt /> <i className="delete-icon" /></a>
    </div>
  </div>
  <div className="position-relative file-container">
    <input type="file" placeholder required title id="select_files" />
    <label htmlFor="select_files" />
    <span>Upload file</span>
    <div className="preview-image">
      <img src="assets/images/sample-doc (1).png" alt />
    </div>
  </div>
  <div className="checkbox-container">
    <input type="checkbox" name id />
    <span className="checkmark" />
  </div>
</div>
    // <!-- Footer links and files CDN -->
    // <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    // <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    // <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
    // <script src="assets/magnific-popup/magnific-popup.js"></script>

    // <script>
    //     $(document).ready(function() {
    //         $('.popup-gallery').magnificPopup({
    //             delegate: 'a',
    //             type: 'image',
    //             tLoading: 'Loading image #%curr%...',
    //             mainClass: 'mfp-img-mobile',
    //             gallery: {
    //                 enabled: true,
    //                 navigateByImgClick: true,
    //                 preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    //             },
    //             image: {
    //                 tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    //                 titleSrc: function(item) {
    //                     return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
    //                 }
    //             }
    //         });
    //     });
    // </script>

}
export default App;
