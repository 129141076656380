import store from "store";


class TokenStore {
    getLocalRefreshToken() {
      const user = store.get('user');
      return user?.refresh_token;
    }

    getUserId() {
      const user =store.get("user");
      return user?.user_id;
    }

    getUserName() {
      const user =store.get("user");
      return user?.first_name;
    }

    getUserFullname() {
      const user =store.get("user");
      return user?.first_name + ' ' + user?.last_name ;
    }

    getUserEmail() {
      const user =store.get("user");
      return user?.email;
    }

    getUserRole() {
      const user =store.get("user");
      return user?.role;
    }

    getUserImage() {
      const user =store.get("user");
      return user?.image;
    }
  
    getLocalAccessToken() {
      const user =store.get("user");
      // console.log(user?.access_token)
      // console.log('Save -- Store',user);
      return user?.access_token;
    }
  
    updateLocalAccessToken(token) {
      let user = store.get("user");
      user.access_token = token;
      store.set("user", user);
    } 
    updateLocalRefreshToken(token) {
      let user = store.get("user");
      user.refresh_token = token;
      store.set("user", user);
    }

    updateOnboarding(status) {
      let user = store.get("user");
      user.onboarding_status = status;
      store.set("user", user);
    }
    
  
    getUser() {
      return  store.get("user");
    }
  
    setUser(user) {
      store.set('user',user)
     }
  
    removeUser() {
        store.remove('user');
     
    }
  }
  
  export default new TokenStore();