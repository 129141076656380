import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [data, setData] = useState({
    new_password: "",
    password: "",
  });

  function validateForm() {
    return data.new_password.length > 0 && data.password.length > 0;
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setError({});
    let errors = {};
    if (!data.new_password) {
      errors.new_password = "Confirm password cannot be empty";
    } else if (data.new_password.length < 4) {
      errors.new_password = "Confirm password must be more than 4 characters";      
    }

    if (!data.password) {
      errors.password = "New password cannot be empty";
    } else if (data.password.length < 4) {
      errors.password = "New password must be more than 4 characters";
    }

    if (errors.new_password || errors.password) {
      setError(errors);
    } else {
      let getforgotPass = store.get("forgotPass");
      if (data.new_password === data.password) {
        let dataObj = {
          otp_token: getforgotPass.otp_token,
          new_password: data.new_password,
        };
        axios({
          method: "post",
          url: `${io.baseURL}/api/v1/auth/reset-password`,
          data: dataObj,
        })
          .then(function (response) {
            if (response.data.type === "success") {
              store.remove("signIn");
              store.set("signIn", response.data.data);
              toast.info(response.data.message, {
                toastId: customId,
              });
              setTimeout(() => {
                navigate("/ShipperDashboard");
              }, 800);
            } else {
              toast.info(response.data.message, {
                toastId: customId,
              });
            }
          })
          .catch(function (error) {
            toast.error(error.response.data.message, {
              toastId: customId,
            });
            // console.log(error.response.data.message);
            // // console.log(error.response.status);
            // // console.log(error.response.headers);
            // console.log("error", error);
          });
      } else {
        toast.error("Password did not match", {
          toastId: customId,
        });
      }
    }
  };

  return (
    <>
      <section className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                      alt
                    />
                  </Link>
                </div>
              </div>
              <div className="form-container">
                <div className="title pb-4">
                  <h3>Re-set your password</h3>
                </div>
                <p>
                  Email verification successful, you can set a new password now
                </p>
                <form onSubmit={formSubmit} className=" login-form">
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Enter new password"
                      onChange={InputEvent}
                      value={data.password}
                      required
                      name="password"
                      error={Error.password ? true : false}
                      id="password"
                    />
                    {Error.password && <small>{Error.password}</small>}
                    <label htmlFor="password" />
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Confirm password"
                      onChange={InputEvent}
                      value={data.new_password}
                      error={Error.new_password ? true : false}
                      required
                      name="new_password"
                      id="new_password"
                    />
                    {Error.new_password && <small>{Error.new_password}</small>}
                    <label htmlFor="new_password" />
                  </div>

                  <div className="submit-button">
                    <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Set new password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
