import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
import instance from "../../../../context/fetchclient";
// import http from "./../../../../context/http.js"
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import DashboardIcons from './../../../partials/DashboardIcons';
import Loader from "../../../partials/loader";
import ImageLoad from "../../../partials/ImageLoad";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TruckList from "../../../partials/Trucks";
import UpdateRef from "../../../partials/Profile/UpdateRef";
import UpdateProfile from "../../../partials/Profile/UpdateProfile";
import UpdatePassword from "../../../partials/Profile/UpdatePassword";
import { AuthHeader } from "../../../partials/Profile/auth-header";


const ProfileSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.number().required().positive().integer(),
  email: yup.string().url(),
  password: yup.string().url(),
  confirm_password: yup.string().url()
});




const customId = "custom-id-yes";

function App() {
  const {register,handleSubmit,formState: { errors }} = useForm({resolver: yupResolver(ProfileSchema)});
  const [getApiProfile,setApiProfile] = useState(null);

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    
  };
  const [getData, setData] = useState({
    name: "",
    phone: "",
    email: "",
    access_token: "",
  });

  const [getUserToken,setUserToken] = useState(store.get("trans-comapny").access_token);
  const [getCount,setCount] = useState({appliedjob:0,readytopick:0,onroute:0,completed:0});
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [isloading, setloading] = useState(false);
  const [truckListData, setTruckListData] = useState({});
  const [ getProfile,setProfile ] = useState(store.get('user'));

  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });

  //  Get User Profile 
  const getUserProfile = () => {
    
  }

  const GetProfileData = () => {
    let shipperRes = store.get("shipper");
    
    instance.get('/profile')
      .then((res)=> {
        // store.set('user',res.data.data);
        setApiProfile(res.data.data);
        console.log('Get Profile',res.data.data);
      })
      .catch(() => {
      
    })
    // setProfile({name:shipperRes.name,email:shipperRes.email,phone:shipperRes.phone,ficaimage:''})


  }

  useEffect(() => {
    GetProfileData();
    console.log('store-user',store.get('user'));
    console.log('trans-comapny',store.get('trans-comapny'));
    // Get current user
    let shipperRes = store.get("trans-comapny");
    if (shipperRes !== undefined) {
      // console.log("!!!!!!!!!!!!!!@@@@@@@@@@@@@!!!!!!!!!!!",shipperRes);
      // getTrucksList(shipperRes.access_token);
      // getDashboard(shipperRes.access_token);
      setData(shipperRes);
      setRefData({
        company_name_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].company_name
            : "",
        description_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].description
            : "",
        phone_number_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].phone
            : "",
        company_name_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].company_name
            : "",
        description_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].description
            : "",
        phone_number_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].phone
            : "",
      });
    }
  }, []);


  function getDashboard(params){
    
    axios({
      method: "get",
      url: `${io.baseURL}/api/v1/dashboard`,
      headers: {
        Authorization: `Bearer ${params}`,
      },
    }).then((response)=> {
      // console.log('Dashboard',response);
      // let Data = {appliedjob:0,readytopick:0,onroute:0,completed:0}
      setCount({appliedjob:response.data.data.applied_jobs,readytopick:response.data.data.ready_to_pick_jobs,onroute:response.data.data.on_route_jobs,completed:response.data.data.completed_jobs});
    }).catch((error) => {
      
    })
  }


  

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setError({});
    let errors = {};
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!getRefData.phone_number_1) {
      errors.phone_number_1 = "This field is required";
    } else if (!re.test(getRefData.phone_number_1)) {
      errors.phone_number_1 = "Invalid phone format";
    }
    if (!getRefData.company_name_1) {
      errors.company_name_1 = "This field is required";
    } else if (getRefData.company_name_1.length > 40) {
      errors.company_name_1 = "Company name must be less than 40 characters";
    }
    if (!getRefData.phone_number_2) {
      errors.phone_number_2 = "This field is required";
    } else if (!re.test(getRefData.phone_number_2)) {
      errors.phone_number_2 = "Invalid phone format";
    }
    if (!getRefData.company_name_2) {
      errors.company_name_2 = "This field is required";
    } else if (getRefData.company_name_2.length > 40) {
      errors.company_name_2 = "Company name must be less than 40 characters";
    }
    if (
      errors.phone_number_1 ||
      errors.phone_number_2 ||
      errors.company_name_1 ||
      errors.company_name_2
    ) {
      setError(errors);
    } else {
      let refShipperData = {
        references: [
          {
            company_name: getRefData.company_name_1,
            description: getRefData.description_1,
            phone: getRefData.phone_number_1,
          },
          {
            company_name: getRefData.company_name_2,
            description: getRefData.description_2,
            phone: getRefData.phone_number_1,
          },
        ],
      };
      let trans_access_token = store.get("trans_access_token");
      // console.log("access_token-dashbiard", trans_access_token);
      setloading(true);
      axios({
        method: "post",
        url: `${io.baseURL}/api/v1/company/update-references`,
        data: refShipperData,
        headers: {
          Authorization: `Bearer ${trans_access_token}`,
        },
      })
        .then(function (response) {
          setloading(false);
          // console.log("🪀🪀🪀🪀🎴", response.data);
          if (response.data.type === "success") {
            store.remove("trans-comapny");
            store.set("trans-comapny", response.data.data);

            toast.info(response.data.message, {
              toastId: customId,
            });
          } else {
            toast.info(response.data.message, {
              toastId: customId,
            });
          }
        })
        .catch(function (error) {
          setloading(false);
          toast.error(error.response.data.message, {
            toastId: customId,
          });
          
        });
    }
  };


  return (
    <>
    <Loader status={isloading} />
      <div>
      <AuthHeader />
        
        {/* Hero section */}
        <DashboardIcons UserToken={getUserToken} UserRole='company' />
       
        {/* Shipper features section */}
        <section className="edit-details">
          <div className="container">
            <div className="row content">
              <div className="col-12 nav-tab-bar">
                <div className="tabs-nav">
                  <nav className="navbar navbar-expand-lg p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarTogglerDemo01"
                      aria-controls="navbarTogglerDemo01"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <img
                        className="pr-2"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icons/bars.svg"
                        }
                        alt
                      />
                      Menu
                    </button>
                    <div
                      className="collapse navbar-collapse nav-tabs"
                      id="navbarTogglerDemo01"
                    >
                      <div className="nav" id="nav-tab" role="tablist">
                        <a
                          className="nav-link active"
                          id="nav-profile-tab"
                          data-toggle="tab"
                          href="#nav-profile"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="true"
                        >
                          <i className="user-small">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/user-small.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit profile
                        </a>
                        <a
                          className="nav-link"
                          id="nav-password-tab"
                          data-toggle="tab"
                          href="#nav-password"
                          role="tab"
                          aria-controls="nav-references"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Update password
                        </a>
                        <a
                          className="nav-link"
                          id="nav-references-tab"
                          data-toggle="tab"
                          href="#nav-references"
                          role="tab"
                          aria-controls="nav-references"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit references
                        </a>
                        <a
                          className="nav-link"
                          id="nav-trucks-tab"
                          data-toggle="tab"
                          href="#nav-trucks"
                          role="tab"
                          aria-controls="nav-trucks"
                          aria-selected="false"
                        >
                          <i className="truck-small">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/truck-small.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Trucks
                        </a>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-12">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade edit-profile active show"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {(getApiProfile == null) ? <Loader status={true} ></Loader> : <UpdateProfile Apidata={getApiProfile} ></UpdateProfile>  }
                   
                  </div>
                  <div
                    className="tab-pane fade edit-references"
                    id="nav-references"
                    role="tabpanel"
                    aria-labelledby="nav-references-tab"
                  >
                    {(getApiProfile == null) ? <Loader status={true} ></Loader> : <UpdateRef Apidata={getApiProfile} ></UpdateRef>  }
                  </div>
                  <div
                    className="tab-pane fade edit-password "
                    id="nav-password"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <UpdatePassword></UpdatePassword>
                   
                  </div>
                 <TruckList></TruckList>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="modal" id="viewDocModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">View Doc</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
             <div class="modal-body">                    
             <div className="preview-image">
              <img
              src={
             process.env.PUBLIC_URL +
              "assets/images/sample-doc (1).png"
              }
              alt
              />
              </div>
             </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="submit-button text-center">
                    <button type="button" data-dismiss="modal" className="btn large grey">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/* Modals delete doc */}
          <div
          className="modal fade"
          id="deleteDocModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
             
                >
                  Are you sure want to remove this doc ?
                </h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn inset-btn small">
                  Yes delete
                </button>
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="after-footer">
          <div className="container after-footer">
            <small>© All rights reserved | Copyright 2022</small>
            <ul className="extra-links">
              <li>
                <a href="#">Privacy policy</a>
              </li>
              <li>
                <a href="#">Terms of service</a>
              </li>
            </ul>
          </div>
        </footer>
        {/* Modals */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
                  id="exampleModalCenterTitle"
                >
                  Are you sure want to remove this truck ?
                </h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn inset-btn small">
                  Yes delete
                </button>
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}
export default App;
