import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate,HashRouter,Outlet } from "react-router-dom";
import ChooseRole from "../pages/choose-role";
import DesignElements from "../pages/design-elements";
import Index from "../pages/index";
//truck-owner
import AddTruck from "../pages/signupProfiles/compant-transporter/add-truck";
import UpdateTruckOwner from "../pages/signupProfiles/owner/edit-truck";
import UpdateTruckCompany from "../pages/signupProfiles/compant-transporter/update-truck";
import TruckDetails from "../pages/signupProfiles/compant-transporter/truck-details";
import TruckDetailsOwner from "../pages/signupProfiles/owner/ViewTruck";
//auth
import EnterOtp from "../pages/auth/enter-otp";
import ForgotPasswordEmail from "../pages/auth/forgot-password-email";
import Login from "../pages/auth/login";
// import Logout from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import ChangePassword from "../pages/auth/change-password";
import ResetPassword from "../pages/auth/reset-password";
//profile
import OwnerDashboard from "../pages/signupProfiles/owner/owner-dashboard";
import ShipperDashboard from "../pages/signupProfiles/shipper/shipper-dashboard";
import CompleteOwnerProfile from "../pages/signupProfiles/owner/complete-owner-profile";
import CompleteShipperProfile from "../pages/signupProfiles/shipper/complete-shipper-profile";
import TransporterDashboard from "../pages/signupProfiles/compant-transporter/transporter-dashboard";
import CompleteTransporterProfile from "../pages/signupProfiles/compant-transporter/complete-transporter-profile";
//Admin
import SuperAdminLogin from "../pages/admin/superAdmin/login"
import AdminLogin from "../pages/admin/admin/login";
import AdminDashboard from "../pages/admin/admin-dashboard";
import AdminAllShippers from "../pages/admin/shippers/admin-all-shiper";
import Admin from "../pages/admin/admin";
// References
import AddReferences from "../pages/signupProfiles/add-references";
import AddTruckReferences from "../pages/signupProfiles/owner/add-truck-references";
//common 
import store from "store";
import About from "../pages/common/about";
import ConnectSupport from "../pages/common/connect-support";
import Faq from "../pages/common/faq";
import PrivacyPolicy from "../pages/common/privacy-policy";
import TermAndService from "../pages/common/term-and-service";
import CopyrightPolicy from "../pages/common/copyright-policy";
import CookiesPolicy from "../pages/common/Cookies-policy";
import Disclaimer from "../pages/common/Disclaimer";




import Amplify from '@aws-amplify/core';
import { Storage, Auth } from 'aws-amplify';
import { ToastContainer, toast } from "react-toastify";
function App(){

  useEffect(() => {
    Amplify.configure({
      Auth: {
          identityPoolId: "ap-south-1:3263f2a4-9bab-41d5-bea2-43975aba5558",
          region: "ap-south-1",
          userPoolId: 'ap-south-1_c8aJzMZqK',
          mandatorySignIn: false,
          userPoolWebClientId: '7vril42r5v6q0gp3p3gpl21ec3'
      },
      Storage: {
          bucket: "miloadapp47a39c2ae0314264824214b5a4ef023e114005-dev",
          region: "ap-south-1",
          identityPoolId: "ap-south-1:3263f2a4-9bab-41d5-bea2-43975aba5558",
      }
  });
  }, []);
  
  const loadImages = () => {
    Storage.list("")
      .then((files) => {
        // console.log(files);
        // setFiles(files);
      })
      .catch((err) => {
        // console.log(err);
      });    
  }

  useEffect(() => {
      Auth.signUp({
        username: 'AmandaB',
        password: 'MyCoolPassword1!',
        attributes: {
            email: 'someemail@example.com',
        },
    });
      // loadImages();
     }, []);


    const LogoutUsers = ({props}) => {
      console.log('Logout User')
      store.clearAll();
      return  <Navigate to='/' ></Navigate>
    }

    const ProtectedRoutes = ({props}) => {
      if(store.get('user')){
        return <Outlet />
      }else{
        toast.error('Please login in application', {
          toastId: 'custom-id-yes',
        });
        return  <Navigate to='/login' replace ></Navigate> 
      }
    }

    const AuthRoutes = ({props}) => {

      switch (store.get('role')){
        case 1:
        return   (store.get('user').onboarding_status) 
                      ? <Navigate to='/ShipperDashboard' replace />  
                      : <Navigate to='/AddReferences' replace />
        break;
        case 2:
          return   (store.get('user').onboarding_status) 
          ? <Navigate to='/TransporterDashboard' replace />  
          : <Navigate to='/AddReferences' replace />
         
        break;
        case 3:
          
          return   (store.get('user').onboarding_status) 
          ? <Navigate to='/OwnerDashboard' replace />  
          : <Navigate to='/AddTruckReferences' replace />
         
        break;

        default:
          return <Outlet />
        break;
      }
    }

    const PublicRoutes = ({props}) => {
      if(store.get('user')){
        return <Outlet />
      }else{
        toast.error('Please login in application', {
          toastId: 'custom-id-yes',
        });
        return  <Navigate to='/login' replace ></Navigate> 
      }

      store.clearAll();
      return  <Navigate to='/' ></Navigate>
    }


 
    return (
      <>
        <HashRouter>
          {/* <Navbar /> */}
          <Routes history={Routes.hashHistory}>



             {/* Public ROutes  */}

            <Route exact path="/" element={<Index />} />
            <Route exact path="/AboutUs" element={<About />} />
            <Route exact path="/TermAndService" element={<TermAndService />} />
            <Route exact path="/ConnectSupport" element={<ConnectSupport />} />

            <Route exact path="/Faq" element={<Faq />} />
            <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route exact path="/CopyrightPolicy" element={<CopyrightPolicy />} />
            <Route exact path="/CookiesPolicy" element={<CookiesPolicy />} />
            <Route exact path="/Disclaimer" element={<Disclaimer />} />

            {/* Auth Routes  Blocked When User is Login  */}

            <Route exact path="/logout" element={<LogoutUsers />} />

            <Route path='/' element={<AuthRoutes />} >
                <Route exact path="/ChooseRole" element={<ChooseRole />} />
                <Route exact path="/ResetPassword" element={<ResetPassword />} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/ChangePassword" element={<ChangePassword />} />
                <Route exact path="/Signup" element={<Signup />} />
                <Route exact path="/ForgotPasswordEmail" element={<ForgotPasswordEmail />} />
                <Route exact path="/CompleteOwnerProfile" element={<CompleteOwnerProfile />} />
                <Route exact path="/CompleteShipperProfile"  element={<CompleteShipperProfile />} />
                <Route exact path="/CompleteTransporterProfile" element={<CompleteTransporterProfile />} />
                <Route exact path="/EnterOtp" element={<EnterOtp />} />
            </Route>

            

            {/* Semi Auth Routes  Blocked When User is Login  */}

          
            {/* <Route exact path="/DesignElements" element={<DesignElements />} /> */}
          

            {/* ProtectedRoutes  Blocked When User is Logout  */}
           
              <Route path="/" element={<ProtectedRoutes />} >
                   <Route exact path="/TransporterDashboard" element={<TransporterDashboard />} />
                   <Route exact path="/ShipperDashboard" element={<ShipperDashboard />} />
                   <Route exact path="/AddTruck" element={<AddTruck />} />
                   <Route exact path="/UpdateTruck" element={<UpdateTruckCompany />} />

                   <Route exact path="/owner/TruckDetails" element={<TruckDetailsOwner />} />
                   <Route exact path="/owner/UpdateTruck" element={<UpdateTruckOwner />} />
                   <Route exact path="/TruckDetails" element={<TruckDetails />} />
                   <Route exact path="/OwnerDashboard" element={<OwnerDashboard />} />
                   <Route exact path="/AddReferences" element={<AddReferences />} />
            <Route exact path="/AddTruckReferences" element={<AddTruckReferences />}  />
              </Route>


          
           
           
            
            

            
            {/* Admin */}
            <Route exact path="/Super-Admin-Login" element={<SuperAdminLogin />} />
            <Route exact path="/Admin-Login" element={<AdminLogin />} />
            <Route exact path="/Admin-Dashboard" element={<AdminDashboard />} />
            <Route exact path="/Admin-All-Shipers" element={<AdminAllShippers />} />
            <Route exact path="/Admin" element={<Admin />} />
           
            {/* <Navigate  to="/"></Navigate > */}
          </Routes>
          {/* <Footer /> */}
        </HashRouter>
         <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      </>
    );
  
}

export default App;
