import { Link } from "react-router-dom"


export const Footer  = () => {
    return ( 
    <>
    <footer className="site-footer">
    <div className="container footer">
      <div className="row">
        <div className="col-12 col-md-5 small-description">
            <img className="footer-logo"
            src={process.env.PUBLIC_URL + "assets/images/logo-white.svg"}
            alt=""
          />
          <small>Logistics Service : Dry-Bulk Grains &amp; Oilseeds</small>
          <hr />
          <small > 
          Unlock the full potential of your grain and oilseed transportation with Mi-Load. Our innovative internet software efficiently connects shippers and transporters, leveraging a vast network to optimize your supply chain. Seamlessly manage logistics, simplify operations, and enhance productivity with our user-friendly platform. Experience the future!
          </small>
        </div>
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md-3 registration-links">
          <h5 className="heading">Registration</h5>
          <ul className="links">
            <li>  <Link to="/Signup">Register as shipper </Link></li>
            <li>  <Link to="/Signup">Register as transporter</Link></li>
            <li>  <Link to="/Signup">Register as vehicle owner</Link></li>
          </ul>
        </div>
        <div className="col-12 col-md-3 quick-links">
          <h5 className="heading">Quick links</h5>
          <ul className="links">
            <li><Link to="/AboutUs">About us</Link></li>
            <li><Link to="/Faq">FAQs</Link></li>
            <li><Link to="/ConnectSupport">Contact support</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <footer className="after-footer">
    <div className="container after-footer">
      <small>© All rights reserved | Copyright 2022</small>
      <ul className="extra-links">
        <li><Link to="/PrivacyPolicy">Privacy policy</Link></li>
        <li><Link to="/CopyrightPolicy">Copyright Policy</Link></li>
        <li><Link to="/CookiesPolicy">Cookies Policy</Link> </li>
        <li><Link to="/Disclaimer">Disclaimer </Link> </li>
     
      </ul>
    </div>
  </footer>
  </>)
}