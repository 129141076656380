import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
import Loader from "../../partials/loader";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import TokenStore from "../../../localstore/TokenStore";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [isloading, setloading] = useState(false);
  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });

  const [getData, setData] = useState({});

  useEffect(() => {
    // Get current user
    let shipperRes = store.get("user");
    if (shipperRes !== undefined) {
      setData(shipperRes);
    }
  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setError({});
    let errors = {};

    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!getRefData.phone_number_1) {
      errors.phone_number_1 = "This field is required";
    } else if (!re.test(getRefData.phone_number_1)) {
      errors.phone_number_1 = "Invalid phone format";
    }

    if (!getRefData.company_name_1) {
      errors.company_name_1 = "This field is required";
    } else if (getRefData.company_name_1.length > 40) {
      errors.company_name_1 = "Company name must be less than 40 characters";
    }
    if (!getRefData.phone_number_2) {
      errors.phone_number_2 = "This field is required";
    } else if (!re.test(getRefData.phone_number_2)) {
      errors.phone_number_2 = "Invalid phone format";
    }

    if (!getRefData.company_name_2) {
      errors.company_name_2 = "This field is required";
    } else if (getRefData.company_name_2.length > 40) {
      errors.company_name_2 = "Company name must be less than 40 characters";
    }

    if (
      errors.phone_number_1 ||
      errors.phone_number_2 ||
      errors.company_name_1 ||
      errors.company_name_2
    ) {
      setError(errors);
    } else {
      let refShipperData = {
        references: [
          {
            company_name: getRefData.company_name_1,
            description: getRefData.description_1,
            phone: getRefData.phone_number_1,
          },
          {
            company_name: getRefData.company_name_2,
            description: getRefData.description_2,
            phone: getRefData.phone_number_1,
          },
        ],
      };
      // console.log(getData, "@@@@@@@@", refShipperData);
      let my_url, my_token;
      //for shipper
      if (getData.role == 1) {
        // console.log("step -1", getData.access_token);
        my_url = `${io.baseURL}/api/v1/shipper/complete-onboarding`;
        my_token = getData.access_token;
        store.remove("shipper_access_token");
        store.set("shipper_access_token", getData.access_token);
      }
      //for company
      if (getData.role == 2) {
        // console.log("step -2", getData.access_token);
        my_url = `${io.baseURL}/api/v1/company/complete-onboarding`;
        my_token = getData.access_token;
        store.remove("trans_access_token");
        store.set("trans_access_token", getData.access_token);
      }
      setloading(true)
      axios({
        method: "post",
        url: my_url,
        data: refShipperData,
        headers: {
          Authorization: `Bearer ${my_token}`,
        },
      })
        .then(function (response) {
          setloading(false)
          // console.log("🪀🪀🪀🪀🎴", response.data);
          if (response.data.type === "success") {
            toast.info(response.data.message, {
              toastId: customId,
            });
            setRefData({
              company_name_1: "",
              description_1: "",
              phone_number_1: "",
              company_name_2: "",
              description_2: "",
              phone_number_2: "",
            });
            // if (response.data.data.role == 3) {
            //   if (response.data.data.onboarding_status == false) {
            //     store.set("AddReferences", response.data.data);
            //     navigate("/AddReferences");
            //   }
            //   if (response.data.data.onboarding_status == true) {
            //     store.set("owner", response.data.data);
            //     navigate("/OwnerDashboard");
            //   }
            // }
            TokenStore.updateOnboarding(response.data.data.onboarding_status);
            store.remove("trans-comapny");
            store.remove("shipper");
            if (response.data.data.role == 2) {
              if (response.data.data.onboarding_status == false) {
                store.set("AddReferences", response.data.data);
                navigate("/AddReferences");
              }
              if (response.data.data.onboarding_status == true) {
                store.set("trans-comapny", response.data.data);
                navigate("/TransporterDashboard");
              }
            }

            if (response.data.data.role == 1) {
              if (response.data.data.onboarding_status == false) {
                store.set("AddReferences", response.data.data);
                navigate("/AddReferences");
              }
              if (response.data.data.onboarding_status == true) {
                store.set("shipper", response.data.data);
                navigate("/ShipperDashboard");
              }
            }
          } else {
            toast.info(response.data.message, {
              toastId: customId,
            });
          }
        })
        .catch(function (error) {
          setloading(false)

          toast.info(error.response.data.message, {
            toastId: customId,
          });
          // console.log(error.response.data.message);
          // console.log("error", error);
        });
    }
  };

  return (
    <>
    <Loader status={isloading} />
      <section className="auth complete-shipper-profile add-references">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-7">
              <div className="form-container">
                <div className="title pb-4">
                  <Link to="/">
                    <button className="btn small back-button">
                      <i className="back-cheveron pr-2" />
                      back
                    </button>
                  </Link>
                  {/* </div>
                  <button className="btn small back-button">
                    <i className="back-cheveron pr-2" />
                    back
                  </button> */}
                  <h3>References (2 required)</h3>
                  <p>Companies you worked with</p>
                </div>
                <form
                  onSubmit={formSubmit}
                  className="form-contol add-references-form"
                >
                  <div className="reference-1">
                    <h5>Reference 1</h5>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Company name"
                        onChange={InputEvent}
                        id="company_name_1"
                        minLength="2"
                        maxLength="40"
                        value={getRefData.company_name_1}
                        error={Error.company_name_1 ? true : false}
                        name="company_name_1"
                        required
                      />
                       {Error.company_name_1 && <small>{Error.company_name_1}</small>}
                      <label htmlFor="company_name_1" />
                    </div>
                    <div className="position-relative">
                      <input
                        type="number"
                        placeholder="Contact number"
                        name="phone_number_1"
                        minLength="5"
                        // pattern="\+971\s[0-9]{3}-[0-9]{6}"
                        value={getRefData.phone_number_1}
                        onChange={InputEvent}
                        id="phone_number_1"
                        error={Error.phone_number_1 ? true : false}
                        required
                      />
                     {Error.phone_number_1 && <small>{Error.phone_number_1}</small>}
                      <label htmlFor="phone_number_1" />
                    </div>
                    <div className="position-relative">
                      <textarea
                        id="description_1"
                        name="description_1"
                        placeholder="Description"
                        value={getRefData.description_1}
                        onChange={InputEvent}
                        required
                      />
                      <label htmlFor="description_1" />
                    </div>
                  </div>
                  <div className="reference-2">
                    <h5>Reference 2</h5>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Company name"
                        id="company_name_2"
                        name="company_name_2"
                        minLength="2"
                        maxLength="40"
                        error={Error.company_name_2 ? true : false}
                        value={getRefData.company_name_2}
                        onChange={InputEvent}
                        required
                      />
                     {Error.company_name_2 && <small>{Error.company_name_2}</small>}
                     
                      <label htmlFor="company_name_2" />
                    </div>
                    <div className="position-relative">
                      <input
                        type="number"
                        placeholder="Contact number"
                        name="phone_number_2"
                        maxLength={10}
                        onChange={InputEvent}
                        value={getRefData.phone_number_2}
                        error={Error.phone_number_2 ? true : false}
                        id="phone_number_2"
                        required
                      />
                  {Error.phone_number_2 && <small>{Error.phone_number_2}</small>}
                      <label htmlFor="phone_number_1" />
                    </div>
                    <div className="position-relative">
                      <textarea
                        name="description_2"
                        id="description_2"
                        placeholder="Description"
                        onChange={InputEvent}
                        value={getRefData.description_2}
                        required
                      />
                      <label htmlFor="description_2" />
                    </div>
                  </div>
                  <div className="submit-button">
                    <button type="submit" className="btn large grey">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
