import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import http from "../../../context/http";
import TokenStore from "../../../localstore/TokenStore";
import instance from "../../../context/fetchclient";
import Loader from "../loader";
import ImageLoad from "../ImageLoad";
import  { Storage } from 'aws-amplify';
import { ToastContainer, toast } from "react-toastify";
import S3Bucket from "../../../services/S3bucket";

const customId = "custom-id-yes";

function UpdateProfile({Apidata}) {

  const ownref = useRef(null);
  const ownref1 = useRef(null);
  const [isloading,setloading] = useState(false);
  const [getProfile,setProfile] = useState({name:Apidata.name ?? '',email:Apidata.email ?? '',phone:Apidata.phone ?? '',

  ficaimage:(Apidata.documents) ? Apidata.documents[0].document_link : (Apidata.fica_documents.length == 0) ? '' : Apidata.fica_documents[0].document_link,
  
  licenseimage:(Apidata.documents) ?  Apidata.documents[1].document_link  : ((Apidata.license_documents) ? Apidata?.license_documents.length : 0  == 0) ? '' : Apidata.license_documents[0].document_link,password:''})
  
  useEffect(() => {
    // console.log('AccessToken',TokenStore.getLocalAccessToken());
    // console.log('RefeshToken',TokenStore.getLocalRefreshToken());  
  },[])

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setProfile(prevState => ({ ...prevState, [name]: value }));
  } 


  const handleLicenseImage = (event) => {
   
    const timestamp = new Date();
    const filename =  timestamp + ownref1.current.files[0].name;

    const params = {
      ACL: 'public-read',
      Body: ownref1.current.files[0],
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: filename
  };

  S3Bucket.putObject(params,(err,data) => {
    if (err) {
      console.error('Error uploading object:', err);
    } else {
      setProfile(prevState => ({ ...prevState, licenseimage:params.Key }));
    }
  })

    // Storage.put(filename,ownref1.current.files[0], {
    //     progressCallback: (progress) => {}
    //   }).then(resp => {
    //     console.log(resp.key);
    //         setProfile(prevState => ({ ...prevState, licenseimage:resp.key }));
    //   }).catch(err => {
    //   });
  }

  const handleFicaImage = (event) => {
   
    const timestamp = new Date();
    const filename =  timestamp + ownref.current.files[0].name;

    const params = {
      ACL: 'public-read',
      Body: ownref.current.files[0],
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: filename
  };


  S3Bucket.putObject(params,(err,data) => {
    if (err) {
      console.error('Error uploading object:', err);
    } else {
      setProfile(prevState => ({ ...prevState, ficaimage:params.Key }));
    }
  })

    


    // Storage.put(filename, ownref.current.files[0], {
    //     progressCallback: (progress) => {}
    //   }).then(resp => {
    //     console.log(resp.key);
    //         setProfile(prevState => ({ ...prevState, ficaimage:resp.key }));
    //   }).catch(err => {
    //   });
  }
  

  const handleUpdateProfile = (e) =>  {
    e.preventDefault();
    console.log(getProfile);


    const data = {
        "name":getProfile.name,
        "password":getProfile.password,
        "fica_documents":[
            getProfile.ficaimage
        ],
        "license_documents":[
            getProfile.licenseimage
        ]
    }
    setloading(true)

    instance.put('/profile',data)
            .then((res) => {
                console.log(res)
                setloading(false)
                toast.success(res.data.message,{ toastId: customId,});
            })
            .catch((err) => {
                setloading(false)
                toast.error(err.response.data.message,{ toastId: customId,});
            })

  }


  return (
     
     Apidata ? 
     <>
     <Loader status={isloading} />
    <div className="col-12 col-md-6">
    <h5>Edit profile</h5>
    <form onSubmit={handleUpdateProfile} className="form-contol edit-profile-form" >
      <div className="position-relative">
      <label className="font-weight-bold text-muted" > Name  <span className="text-danger h4" > * </span> </label>

        <input
          onChange={handleChangeInput}
          type="text"
          name="name"
          placeholder="Company name"
          minLength="2"
          maxLength="40"
          required
          value={getProfile.name}
        />
       
      </div>
      <div className="position-relative">
      <label className="font-weight-bold text-muted" > Email  <span className="text-danger h6" >  </span> </label>
        
        <input
          type="text"
          placeholder="Email"
          minLength="2"
          maxLength="10"
          name="email"
        //   onChange={handleChangeInput}
          required
          disabled
          value={getProfile.email}
        />
        {/* <label htmlFor /> */}
      </div>
      <div className="position-relative">
      <label className="font-weight-bold text-muted" > Phone  <span className="text-danger h6" >  </span> </label>

        <input
          type="number"
          placeholder="Phone number"
          minLength="2"
          maxLength="10"
          required
          name="phone"
          disabled
          onChange={handleChangeInput}
          value={getProfile.phone}
        />
        
      </div>
      <label className="font-weight-bold text-muted mt-4" > Fica Image  </label>
      <div className="position-relative file-container">
    

        <input
          type="file"
          ref={ownref}
          placeholder
          title
          name="ficaimage"
          onChange={handleFicaImage}
        />
        <i
          className="open-eye"
          data-toggle="modal"
          data-target="#viewDocModal"
        />
       
        <div className="preview-image">

            {/* {getProfile.ficaimage == '' ? '' :  */}
            <ImageLoad ImageId={getProfile.ficaimage} />
            
            {/* } */}
        </div>
       
      
      </div>
      { (Apidata.role === 1) ? '' : <><label className="font-weight-bold text-muted mt-4" > License Image  </label>
      <div className="position-relative file-container">
    

        <input
          type="file"
          ref={ownref1}
          placeholder
          title
          name="licenseimage"
          onChange={handleLicenseImage}
        />
        <i
          className="open-eye"
          data-toggle="modal"
          data-target="#viewl"
        />
       
        <div className="preview-image">

            {/* {getProfile.ficaimage == '' ? '' :  */}
            <ImageLoad ImageId={getProfile.licenseimage} />
            
            {/* } */}
        </div>

       
      
      </div> 
      
      <div class="modal" id="viewl">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">View Doc</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">         
              <center>
                
                    <div className="preview-image">
                              <ImageLoad width='100%' className="img-responsive"  ImageId={getProfile.licenseimage} />
                          </div>
                </center>           
                   
                             
             </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="submit-button text-center">
                    <button type="button" data-dismiss="modal" className="btn large grey">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> </>  }
      <div className="position-relative">
      <label className="font-weight-bold text-muted" > Current password  <span className="text-danger h4" >  * </span> </label>

        <input
          type="password"
          minLength="2"
          maxLength="40"
          placeholder="Enter password"
          required
          onChange={handleChangeInput}
          name="password"
        />
      
      </div>
      <div className="submit-button text-center">
        <button type="submit" className="btn large grey">Save</button>
      </div>
    </form>
  </div>
  <div class="modal" id="viewDocModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">View Doc</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">                    
                   <center>
                      <div className="preview-image">
                        <ImageLoad width='100%' className="img-responsive"  ImageId={getProfile.ficaimage} />            
                      </div>
                   </center>
             </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="submit-button text-center">
                    <button type="button" data-dismiss="modal" className="btn large grey">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </> : <Loader status={true} ></Loader>);
}

export default UpdateProfile;

