import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
import Loader from "../../../partials/loader";
import ImageLoad from "../../../partials/ImageLoad";
import instance from "../../../../context/fetchclient";
import TruckObject from './truckobj.json'

// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const [Error, setError] = useState({});
  const [showToken, setToken] = React.useState("");
  const [user, setUser] = React.useState(store.get('user'));

  const [showTransID, setTransID] = React.useState("");
  const [isloading,setloading] = React.useState(true);
  const [getDriverDetails,setDriverDetails] = React.useState({"driver_email": "loading...","driver_password": "loading..."});
  let location = useLocation();
  const [truckDatails, setTruckDatails] = useState({});
  const [MyTruckDetails, SetMyTruckDetails] = useState(TruckObject);

  useEffect(() => {
    let trans_access_token, trans_id, access_token;
    trans_access_token = store.get("trans-comapny");
    // console.log("access_token my add trcuk", trans_access_token);
    if (trans_access_token !== undefined) {
      access_token = trans_access_token.access_token;
    }
    // console.log("========", location.search.split("?")[1]);
    if (location.search.split("?")[1]) {
      trans_id = location.search.split("?")[1];
    }
    getTruckDatails(trans_id, access_token);
  }, [location]);


  const GetDriverDetails = (e,DriverId) => {
    console.log('DriverId',DriverId)
    instance.post(`/company/driver/credentials`,{driver_id:DriverId})
      .then((response) => { 
        setDriverDetails(response.data.data);
        console.log('Driver Details ', response.data)
       })
      .catch((err) => { console.log(err) })
  }

  function getTruckDatails(trans_id, access_token) {
    setloading(true);
    instance.get(`/company/truck-details?truck_id=${trans_id}`)
      .then(function (response) {
        setloading(false);
        if (response.data.type === "success") {
          console.log('Truck Data ',response.data.data)
          SetMyTruckDetails(response.data.data);
          // GetDriverDetails(response.data.data.driver.driver_id);
          setTruckDatails({
            driverName: response.data.data.driver.name
              ? response.data.data.driver.name
              : "N/A",
            driverPhone: response.data.data.driver.phone
              ? response.data.data.driver.phone
              : "N/A",
            driverUser_documents: response.data.data.driver.user_documents.length ? response.data.data.driver.user_documents : process.env.PUBLIC_URL +
            "assets/images/doc_icon.png",
            driverUser_number: response.data.data.driver.user_number
              ? response.data.data.driver.user_number
              : "N/A",
            driverModel: response.data.data.driver.model
              ? response.data.data.driver.model
              : "N/A",
            trailers: response.data.data.trailers
              ? response.data.data.trailers
              : "N/A",
            truckModel: response.data.data.model
              ? response.data.data.model
              : "N/A",
            truck_number: response.data.data.truck_number
              ? response.data.data.truck_number
              : "N/A",
            truck_year: response.data.data.year
              ? response.data.data.year
              : "N/A",
          });
        }
      })
      .catch(function (error) {
        setloading(false);

        // toast.error(error.response.data.message, {
        //   toastId: customId,
        // });

        // console.log(error.response.data.message);
        // console.log("error", error);
      });
  }

  return (
    <div>
      <Loader status={isloading} ></Loader>
      {/* Hero section */}
      <section className="add-truck-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Link to="/TransporterDashboard">
            
                <button className="btn small back-button">
                  <i className="back-cheveron pr-2" />
                  Back to dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Shipper features section */}
      <section className="add-truck truck-details-page">
        <div className="container">
          <div className="row content  justify-content-center">
            <div className="col-12">
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 text-center illustration">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/trucks-illustration (1).png"
                    }
                    alt
                  />
                </div>
                <div className="col-12 headings">
                  <h5>Truck details</h5>
                </div>
                <div className="col-12 truck-details">
                  <div className="col-12 col-md-8 basic-details">
                    <h2>{MyTruckDetails.model}</h2>
                    <h4 className="truck-specs"> 
                    {MyTruckDetails.truck_type_detail.name} | {MyTruckDetails.truck_manufacturer.name} | {MyTruckDetails.year} 
                    </h4>
                    <h5>Truck Number : {MyTruckDetails.truck_number}</h5>
                    <h5>Regn. no. : {MyTruckDetails.registration_number}</h5>
                  </div>
                  <div className="col-12 col-md-4 documents">
                    <p>Document</p>
                    <div className="doc-image">
                    {MyTruckDetails ?  
                      <ImageLoad  ImageId={MyTruckDetails.TruckDocuments[0].document_link} ></ImageLoad> : 
                      <img  src={process.env.PUBLIC_URL +"assets/images/doc_icon.png"} alt />}
                    </div>
                  </div>
                </div>
                <div className="col-12 headings">
                  <h5 className="trailers-heading">
                    Trailers <span> :- {MyTruckDetails ? MyTruckDetails.trailers.length :'loading..'} </span>
                  </h5>
                </div>
                <div className="col-12 trailers-details">
                  <div className="row">
                    {MyTruckDetails !== undefined &&
                    MyTruckDetails
                      ? MyTruckDetails.trailers.map((el, key) => (
                          <div
                            key={key}
                            className="col-12 col-md-6 basic-details p-4"
                          >
                            {/* <hr /> */}
                            <small className="text-muted h5" > Trailer {key+1} </small>
                            <hr  />
                            <h2 className="h4" >{el.model}</h2>
                            <h5  className="h6" >Regn. no. : <span className="text-dark"> {el.registration_number} </span> </h5>

                            {el.trailer_documents.map((tl, key1) => (
                              <div className="doc-image">
                              
                                <ImageLoad key={key1} ImageId={tl.document_link} ></ImageLoad>
                              
                              </div>
                            ))}
                          </div>
                        ))
                      : "No Record Found"}

                    {/* <div className="col-12 col-md-6 basic-details">
                      <h2>Trailer 2</h2>
                      <h5>Regn. no. : AM - 302-213</h5>
                      <div className="doc-image">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/sample-doc (1).png"
                          }
                          alt
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-12 headings">
                  <h5>Driver details</h5>
                </div>
                <div className="col-12 driver-details">
                  <div className="col-12 col-md-3 text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/driver-illustration (1).png"
                      }
                      alt
                    />
                  </div>
                  <div className="col-12 col-md-5 basic-details">
                    <h2>{MyTruckDetails.driver.name}</h2>
                    <h5 className="driver-id">
                      Driver ID : <span>{MyTruckDetails.driver.user_number}</span>
                    </h5>
                    <h5 className="driver-id">
                      Driver Phone No. : <span>{MyTruckDetails.driver.phone}</span>
                    </h5>
                    
                    <p>
                      <button
                        className="btn small inset-btn share-button"
                        data-toggle="modal"
                        data-target="#viewDocModal"
                        onClick={(e) => GetDriverDetails(e,MyTruckDetails.driver.driver_id)}
                      >
                        <i className="delete">
                          <img
                            src="assets/images/icons/dashboard-icons/share.svg"
                            alt
                          />
                        </i>
                      </button> 
                      share credentials
                    </p>
                  </div>
                  <div className="col-12 col-md-4 documents">
                    <p>Documents</p>
                    <div className="doc-image">
                      <ImageLoad ImageId={MyTruckDetails.driver.company_driver_details.license_doc} ></ImageLoad>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-right actions">
              <Link to={`/UpdateTruck?${MyTruckDetails.truck_id}`}  className="btn large grey edit-button mr-3">
                <i className="pencil">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/dashboard-icons/pencil.svg"
                    }
                    alt
                  />
                </i>{" "}
                Edit detils
              </Link>
              {/* <button
                className="btn large inset-btn delete-button"
                data-toggle="modal"
                data-target="#deleteModal"
              >
                <i className="delete">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/trash-orange.svg"
                    }
                    alt
                  />
                </i>
              </button> */}
            </div>
          </div>
        </div>
        <div class="modal" id="viewDocModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Driver Credentials </h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">                    
                    <span className="text-muted h6" > Email :- </span>  <span className="h6" >{getDriverDetails.driver_email}   </span>   
                    <br />
                    <span className="text-muted h6" > Password :- </span>   <span className="h6" > {getDriverDetails.driver_password}  </span>
             </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="submit-button text-center">
                    <button type="button" data-dismiss="modal" className="btn large grey">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="after-footer">
        <div className="container after-footer">
          <small>© All rights reserved | Copyright 2022</small>
          <ul className="extra-links">
            <li>
              <a href="#">Privacy policy</a>
            </li>
            <li>
              <a href="#">Terms of service</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
export default App;
