import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
import SideNavBar from "../admin-navbar";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const [getData, setData] = useState({
    name: "",
    phone: "",
    access_token: "",
  });
  const navigate = useNavigate();
  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });

  useEffect(() => {
    // Get current user
    let shipperRes = store.get("shipper");
    if (shipperRes !== undefined) {
      setData(shipperRes);
    }
  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    let refShipperData = {
      references: [
        {
          company_name: getRefData.company_name_1,
          description: getRefData.description_1,
          phone: getRefData.phone_number_1,
        },
        {
          company_name: getRefData.company_name_1,
          description: getRefData.description_2,
          phone: getRefData.phone_number_1,
        },
      ],
    };
    // console.log("@@@@@@@@@@@@", getData.access_token);
    // console.log("refShipperData", refShipperData);
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/company/complete-onboarding`,
      data: refShipperData,
      headers: {
        Authorization: `Bearer ${getData.access_token}`,
      },
    })
      .then(function (response) {
        // console.log("🪀🪀🪀🪀🎴", response.data);
        if (response.data.type === "success") {
          // store.remove("signIn");
          // store.set("signIn", response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          // setTimeout(() => {
          //   navigate("/ShipperDashboard");
          // }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // // console.log(error.response.status);
        // // console.log(error.response.headers);
        // console.log("error", error);
      });
  };
  return (
    <>
      <div>
        <div>
          <SideNavBar/>
          {/* Shipper features section */}
          <section className="edit-details">
            <div className="container">
              <div className="row content">
                <div className="col-12">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className=" trucks-list"
                      id="nav-trucks"
                      role="tabpanel"
                      aria-labelledby="nav-trucks-tab"
                    >
                      <div className="col-12 p-0">
                        <table
                          className="trucks-table"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          width="100%"
                        >
                          <tbody>
                            <tr className="truck-item">
                              <td>
                                <h5 className="truck-name">Pickup truck</h5>
                              </td>
                              <td>
                                <p className="truck-specs">
                                  TATA | Pickup | T-c67
                                </p>
                              </td>
                              <td>
                                <Link to="#">
                                  <span className="truck-reg-no">
                                    <i className="eye-open">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/dashboard-icons/eye.svg"
                                        }
                                        alt
                                      />
                                    </i>
                                    AM - 302-213
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <p className="trailers">2 trailers</p>
                              </td>
                              <td>
                                <p className="driver-name">
                                  Driver : <span>Friedrick</span>
                                </p>
                              </td>
                              <td>
                                <a
                                  href="#"
                                  className="btn small grey edit-button"
                                >
                                  <i className="open-eye">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/dashboard-icons/eye.svg"
                                      }
                                      alt
                                    />
                                  </i>{" "}
                                  View
                                </a>
                              </td>
                              <td>
                                <button
                                  className="btn small inset-btn delete-button"
                                  data-toggle="modal"
                                  data-target="#deleteModal"
                                >
                                  <i className="delete">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/trash-orange.svg"
                                      }
                                      alt
                                    />
                                  </i>
                                </button>
                              </td>
                            </tr>
                            <tr className="spacer" />
                            <tr className="truck-item">
                              <td>
                                <h5 className="truck-name">Pickup truck</h5>
                              </td>
                              <td>
                                <p className="truck-specs">
                                  TATA | Pickup | T-c67
                                </p>
                              </td>
                              <td>
                                <Link to="#">
                                  <span className="truck-reg-no">
                                    <i className="eye-open">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/dashboard-icons/eye.svg"
                                        }
                                        alt
                                      />
                                    </i>
                                    AM - 302-213
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <p className="trailers">2 trailers</p>
                              </td>
                              <td>
                                <p className="driver-name">
                                  Driver : <span>Friedrick</span>
                                </p>
                              </td>
                              <td>
                                <a
                                  href="#"
                                  className="btn small grey edit-button"
                                >
                                  <i className="eye-open">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/dashboard-icons/eye.svg"
                                      }
                                      alt
                                    />
                                  </i>{" "}
                                  View
                                </a>
                              </td>
                              <td>
                                <button className="btn small inset-btn delete-button">
                                  <i className="delete">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/trash-orange.svg"
                                      }
                                      alt
                                    />
                                  </i>
                                </button>
                              </td>
                            </tr>
                            <tr className="spacer" />
                            <tr className="truck-item">
                              <td>
                                <h5 className="truck-name">Pickup truck</h5>
                              </td>
                              <td>
                                <p className="truck-specs">
                                  TATA | Pickup | T-c67
                                </p>
                              </td>
                              <td>
                                <Link to="#">
                                  <span className="truck-reg-no">
                                    <i className="eye-open">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/dashboard-icons/eye.svg"
                                        }
                                        alt
                                      />
                                    </i>
                                    AM - 302-213
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <p className="trailers">2 trailers</p>
                              </td>
                              <td>
                                <p className="driver-name">
                                  Driver : <span>Friedrick</span>
                                </p>
                              </td>
                              <td>
                                <a
                                  href="#"
                                  className="btn small grey edit-button"
                                >
                                  <i className="eye-open">
                                    <img
                                      src="assets/images/icons/dashboard-icons/eye.svg"
                                      alt
                                    />
                                  </i>{" "}
                                  View
                                </a>
                              </td>
                              <td>
                                <button className="btn small inset-btn delete-button">
                                  <i className="delete">
                                    <img
                                      src="assets/images/icons/trash-orange.svg"
                                      alt
                                    />
                                  </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer */}
          <footer className="after-footer">
            <div className="container after-footer">
              <small>© All rights reserved | Copyright 2022</small>
              <ul className="extra-links">
                <li>
                  <a href="#">Privacy policy</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>

      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
