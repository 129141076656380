import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Footer } from "../../partials/Public/Footer";
import { Header } from "../../partials/Public/header";
function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
    <Header></Header>


    {/* Vehicle owner features section */}
    <section className=" vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <div className="row features">
              <div className="col-12">
                <div className="heading  d-flex align-items-center justify-content-center">
                 
                 <u>
                    <h3> Disclaimer</h3>
                 </u>
                  

                </div>
              </div>
              <div className="col-12 col-md-12">
               <h5>Interpretation and Definitions</h5>
               <h5>Interpretation</h5>
               <p>The words in which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>

               <h5>Definitions</h5>
               <p>For this Disclaimer:</p>

               <ul>
               <li><p>Mi-Load  (referred to as either "Mi-Load", "We", "Us", or "Our" in this Disclaimer) refers to Mi-Load.</p></li>
               <li><p>Service refers to the Website & Application.</p></li>
               <li><p>Customer mean the shipper and transporters accessing the Service, or Mi-Load, or other legal entity on behalf of which such cu is accessing or using the Service, as applicable.</p></li>
                <li><p>Website refers to Mi-Load, accessible from www.mi-load.co.za </p></li>
                <li><p>Application refers to Mi-Load, accessible from Google play store & Apple play store </p></li>
               </ul>
               <h5>Disclaimer</h5>
               <p>The information contained in our Service is for general information purposes only.</p>
               <p>Mi-Load assumes no responsibility for errors or omissions in the contents of our Service.</p>
               <p>In no event shall the Mi-Load be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence, or another tort, arising out of or in connection with the use of the Service or the contents of our Service. Mi-load reserves the right to make additions, deletions, or modifications to the contents of our Service at any time without prior notice.</p>
               <p>Mi-Load does not warrant that our Service is free of viruses or other harmful components.</p>
               <h5>Errors and Omissions Disclaimer</h5>
               <p>The information given by Our Service is for general guidance on matters of interest only. Even if Mi-Load takes every precaution to ensure that the content of our Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules, and regulations, there may be delays, omissions, or inaccuracies in the information contained on the Service.</p>
               <p>Mi-Load is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
               <h5>Fair Use Disclaimer</h5>
               <p>Mi-Load may use copyrighted material that has not always been specifically authorized by the copyright owner.</p>
               <p>Mi-Load believes this constitutes a "fair use" of any such copyrighted.</p>
               <p>If the ‘Customer’ wish to use copyrighted material from the Service for your purposes that go beyond fair use, The‘Customer’ must obtain permission from the copyright owner.</p>
               <h5>No Responsibility Disclaimer</h5>
               <p>The information on the Service is provided with the understanding that Mi-Load is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal, or other competent advisers.</p>
               <p>In no event shall Mi-Load be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use our Service.</p>
               <h5>"Use at Your Own Risk" Disclaime</h5>
               <p>All information and usage of this Service is provided "as is", with no guarantee of completeness, accuracy, timelines, or of the results obtained from the use of this service or information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</p>
               <p>Mi-Load will not be liable to Customer or anyone else for any decision made or action taken in reliance on the information given by the Service or use of this Service for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
    <Footer></Footer>
  </div>
  
  );
}

export default App;
