import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../partials/loader";
import Buttonloading from "../../partials/Buttonloading";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [data, setData] = useState({
    email: "",
    type: "registration",
  });
  const [isloading,setloadning] = useState(false);

  function validateForm() {
    return data.email.length > 0;
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  
  const formSubmit = (e) => {

    e.preventDefault();
    setError({})
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!data.email) {
      errors.email = "This field is required";
    } else if (!regex.test(data.email)) {
      errors.email = "Invalid email format";
    }

    if(errors.email){
      setError(errors)
    } else {
      setloadning(true);
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/auth/email-verification`,
      data: data,
    })
      .then(function (response) {
        if (response.data.type === "success") {
          store.remove("signUp");
          store.remove("forgotPass");
          store.set("signUp", response.data.data);
          setloadning(false);

          toast.info(response.data.message, {
            toastId: customId,
          });
          navigate("/EnterOtp");
         } else {
          setloadning(false);
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        setloadning(false);
        toast.error(error.response.data.message, {
          toastId: customId,
        });
      });
    }
  };

  return (
    <>
      <Loader status={isloading} />
      <section className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                      alt="no logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="form-container signup">
                <div className="title">
                  <h3>Signup</h3>
                  <Link to="/Login">Existing user ? Login</Link>
                </div>
                <p>
                  Enter email, we will send OTP (One Time Password) for email
                  verification
                </p>
                <form onSubmit={formSubmit} className="form-contol login-form">
                  <div className="position-relative">
                    <input
                      type="email"
                      placeholder="Enter email"
                      onChange={InputEvent}
                      value={data.email}
                      required
                      error ={Error.email ?  true : false }
                      name="email"
                      id="email"
                    />
                      {Error.email && <small >{Error.email}</small>}
                    <label htmlFor="email" />
                  </div>
                  <div className="submit-button">
                    {/* <link to="/EnterOtp" /> */}
                    <Buttonloading 
                  loading={isloading} 
                  type='submit' 
                  isdisabled={!validateForm()} 
                  classes="btn large grey"  > 
                    Continue 
                  </Buttonloading>
                    {/* <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Continue
                    </button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
}
export default App;
