import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const Axios = axios.create({
//   baseURL: BASE_URL,
// });
// console.log("@@@@@@2",process.env.REACT_APP_BASE_URL);
export default {
  baseURL:"https://api.mi-load.com"
}