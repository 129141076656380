import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
// React-Toastify
import DashboardIcons from './../../../partials/DashboardIcons';
import UpdateProfile from "../../../partials/Profile/UpdateProfile";
import UpdatePassword from "../../../partials/Profile/UpdatePassword";
import instance from "../../../../context/fetchclient";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../partials/loader";
import TokenStore from "../../../../localstore/TokenStore";
import { AuthHeader } from "../../../partials/Profile/auth-header";
const customId = "custom-id-yes";

function App() {
  const [getData, setData] = useState({
    name: "",
    phone: "",
    email: "",
    access_token: "",
  });
  const [getApiProfile,setApiProfile] = useState(null);

  const navigate = useNavigate();
  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });
  const GetProfileData = () => {
    let shipperRes = store.get("shipper");
    
    instance.get('/profile')
      .then((res)=> {
        setApiProfile(res.data.data);
        console.log('Get Profile',res.data.data);
      })
      .catch(() => {
      
    })
  


  }

  const [getUserToken,setUserToken] = useState(store.get("owner").access_token);
  useEffect(() => {
   
    let shipperRes = store.get("owner");
    // console.log('userdetails', shipperRes);
    GetProfileData();
    if (shipperRes !== undefined) {
      setData(shipperRes);
      GetProfileData();
    }

  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

 
  return (
    <>
      <div>
        <AuthHeader></AuthHeader>
        {/* Hero section */}
        <DashboardIcons UserToken={getUserToken} UserRole={'owner'} />
        {/* <section className="quick-checks">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row quick-view-cards">
                  <div className="col-6 col-md-3">
                    <div className="card">
                      <i className="applied-list" />
                      <hr />
                      <h3>24</h3>
                      <h5>Applied jobs</h5>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="card">
                      <i className="truck" />
                      <hr />
                      <h3>12</h3>
                      <h5>Ready to pick</h5>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="card">
                      <i className="on-route" />
                      <hr />
                      <h3>5</h3>
                      <h5>On route</h5>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="card">
                      <i className="check-completed" />
                      <hr />
                      <h3>76</h3>
                      <h5>Completed</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Shipper features section */}
        <section className="edit-details">
          <div className="container">
            <div className="row content">
              <div className="col-12 nav-tab-bar">
                <div className="tabs-nav">
                  <nav className="navbar navbar-expand-lg p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarTogglerDemo01"
                      aria-controls="navbarTogglerDemo01"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <img
                        className="pr-2"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icons/bars.svg"
                        }
                        alt
                      />
                      Menu
                    </button>
                    <div
                      className="collapse navbar-collapse nav-tabs"
                      id="navbarTogglerDemo01"
                    >
                      <div className="nav" id="nav-tab" role="tablist">
                        <a  
                          className="nav-link"
                          id="nav-edit-profile"
                          data-toggle="tab"
                          href="#edit-profile"
                          role="tab"
                          aria-controls="nav-edit-profile"
                          aria-selected="true" >
                          <i className="user-small">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/user-small.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit profile
                        </a>
                        {/* <a
                          className="nav-link"
                          id="nav-references-tab"
                          data-toggle="tab"
                          href="#nav-references"
                          role="tab"
                          aria-controls="nav-references"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit references
                        </a> */}
                        <a
                          className="nav-link"
                          id="nav-password-tab"
                          data-toggle="tab"
                          href="#nav-password"
                          role="tab"
                          aria-controls="nav-password"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Update password 
                        </a>
                        <Link
                          
                          to={`/owner/TruckDetails?${(getApiProfile) ? getApiProfile.truck_detail.truck_id : ''}`}
                          className="nav-link"
                          id="nav-trucks-tab"
                          data-toggle="tab"
                          //href="#nav-trucks"
                          role="tab"
                          aria-controls="nav-trucks"
                          aria-selected="false"
                        >
                          <i className="truck-small">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/truck-small.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit truck details
                        </Link>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-12">
              <div className="tab-content" id="nav-edit-profile">
                  <div
                    className="tab-pane fade edit-profile active show"
                    id="edit-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {(getApiProfile == null) ? <Loader status={true} ></Loader> : <UpdateProfile Apidata={getApiProfile} ></UpdateProfile>  }
                   
                  </div>
                   {/*  Update Password Tab Start   */}
                   <div
                    className="tab-pane fade edit-password "
                    id="nav-password"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <UpdatePassword></UpdatePassword>
                   
                  </div>
                  <div
                    className="tab-pane fade edit-references"
                    id="nav-references"
                    role="tabpanel"
                    aria-labelledby="nav-references-tab"
                  >
              </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="modal" id="viewDocModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">View Doc</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
             <div class="modal-body">                    
             <div className="preview-image">
              <img
              src={
             process.env.PUBLIC_URL +
              "assets/images/sample-doc (1).png"
              }
              alt
              />
              </div>
             </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="submit-button text-center">
                    <button type="button" data-dismiss="modal" className="btn large grey">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

          {/* Modals delete doc */}
          <div
          className="modal fade"
          id="deleteDocModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
             
                >
                  Are you sure want to remove this doc ?
                </h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn inset-btn small">
                  Yes delete
                </button>
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="after-footer">
          <div className="container after-footer">
            <small>© All rights reserved | Copyright 2022</small>
            <ul className="extra-links">
              <li>
                <a href="#">Privacy policy</a>
              </li>
              <li>
                <a href="#">Terms of service</a>
              </li>
            </ul>
          </div>
        </footer>
        {/* Modals */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
                  id="exampleModalCenterTitle"
                >
                  Are you sure want to remove this truck ?
                </h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn inset-btn small">
                  Yes delete
                </button>
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}
export default App;
