import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
import Loader from "../../partials/loader";
import Buttonloading from "../../partials/Buttonloading";



// React-Toastify
import { toast } from "react-toastify";
const customId = "custom-id-yes";


function App() {
  const navigate = useNavigate();
  const [Error, setError] = useState({})
  const [data, setData] = useState({
    email: "",
    password: "",
    device_id: "",
    device_type: "WEB",
    device_token: "357d8f10-464c-4e93-b0b9-7784d9011042", //FCM token for push notifications
  });
  const [isloading,setloadning] = useState(false);


  useEffect(()=> {

    switch (store.get('role')) {
      case 1:
        (store.get('user').onboarding_status) ?  navigate("/ShipperDashboard") :  navigate("/ShipperDashboard")
       
      break;
      case 2:
        (store.get('user').onboarding_status ) ?  navigate("/TransporterDashboard") :  navigate("/ShipperDashboard")

       
      break;
      case 3:
        (store.get('user').onboarding_status ) ?  navigate("/OwnerDashboard") :  navigate("/ShipperDashboard")

       
      break;
          
      default:

      break;
    }
      // if(store.get('role')){

      // }
  },[]);


  function validateForm() {
    return data.email.length > 0 && data.password.length > 0;
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    setError({})
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!data.email) {
      errors.email = "This field is required";
    } else if (!regex.test(data.email)) {
      errors.email = "Invalid email format";
    }

    if (!data.password) {
      errors.password = "This field is required";
    } else if (data.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }

    if(errors.email  || errors.password){
      setError(errors)
    }else{
      setloadning(true)
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/auth/login`,
      data: data,
    }).then(function (response) {

        if (response.data.type === "success") {
          toast.info(response.data.message, { toastId: customId });
          store.set('role',response.data.data.role);
          store.set('user',response.data.data);
          //// console.log('user',response.data.data);

          store.remove("signIn");
          store.set("signIn", response.data.data);
          store.remove("shipper");
          store.remove("owner");
          store.remove("trans-comapny");
          store.remove("AddReferences");
          store.remove("AddReferences");
          setloadning(false)
          if (response.data.data.role == 3) {
            if (response.data.data.onboarding_status == false) {
              store.set("AddReferences", response.data.data);
              navigate("/AddTruckReferences");
              // setTimeout(() => {
                
              // }, 800);
            }
            if (response.data.data.onboarding_status == true) {
              store.set("owner", response.data.data);
              navigate("/OwnerDashboard");
              // setTimeout(() => {
               
              // }, 800);
            }
          }

          if (response.data.data.role == 2) {
            if (response.data.data.onboarding_status == false) {
              store.set("AddReferences", response.data.data);
              navigate("/AddReferences");
              // setTimeout(() => {
              //   navigate("/AddReferences");
              // }, 800);
            }
            if (response.data.data.onboarding_status == true) {
              store.set("trans-comapny", response.data.data);
              navigate("/TransporterDashboard");
              // setTimeout(() => {
              //   navigate("/TransporterDashboard");
              // }, 800);
            }
          }

          if (response.data.data.role == 1) {
            if (response.data.data.onboarding_status == false) {
              store.set("AddReferences", response.data.data);
              navigate("/AddReferences");
              // setTimeout(() => {
              //   navigate("/AddReferences");
              // }, 800);
            }
            if (response.data.data.onboarding_status == true) {
              store.set("shipper", response.data.data);
              navigate("/ShipperDashboard");
              // setTimeout(() => {
              //   navigate("/ShipperDashboard");
              // }, 800);
            }
          }
        } else {
          setloadning(false)
          toast.info(response.data.message, { toastId: customId });
        }
      }).catch(function (error) {
        setloadning(false)
        toast.error(error.response.data.message, {
          toastId: customId,
        });
      });
    }
  };

  return (
    <>
     <Loader status={isloading} />
      <section  className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                      alt
                    />
                  </Link>
                </div>
              </div>
              <div className="form-container">
                <div className="title pb-4">
                  <h3>Login</h3>
                  <Link to="/Signup">New user ? Signup</Link>
                </div>
                <form onSubmit={formSubmit} className=" login-form">
                  <div className="position-relative">
                    <input
                      type="email"
                      placeholder="Enter email"
                      onChange={InputEvent}
                      value={data.email}
                      required
                      error ={Error.email ?  true : false }
                      name="email"
                      id="email"
                    />
                     {Error.email && <small >{Error.email}</small>}
                    <label htmlFor="email" />

                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Enter password"
                      onChange={InputEvent}
                      value={data.password}
                      required
                      error ={Error.password ?  true : false }
                      name="password"
                      id="password"
                    />
                    {Error.password && <small >{Error.password}</small>}
                
                    <label htmlFor="password" />
                  </div>
                  <div className="signed-in">
                    <div className="checkbox-container">
                      <input type="checkbox" name id="keep-signin" />
                      <span className="checkmark" />
                      <label htmlFor="keep-signin">Keep me signed in</label>
                    </div>
                    <Link to="/ForgotPasswordEmail">forgot password ?</Link>
                  </div>
                  <div className="submit-button">

                  <Buttonloading 
                  loading={isloading} 
                  type='submit' 
                  isdisabled={!validateForm()} 
                  classes="btn large grey"  > 
                    Login 
                  </Buttonloading>
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
}

export default App;
