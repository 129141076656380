import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import DashboardIcons from './../../../partials/DashboardIcons';
import ImageLoad from "../../../partials/ImageLoad";
import UpdateRef from "../../../partials/Profile/UpdateRef";
import UpdateProfile from "../../../partials/Profile/UpdateProfile";
import UpdatePassword from "../../../partials/Profile/UpdatePassword";
import instance from "../../../../context/fetchclient";
import Loader from "../../../partials/loader";
import {Storage} from 'aws-amplify';
import { AuthHeader } from "../../../partials/Profile/auth-header";


const customId = "custom-id-yes";

function App() {
const ownref = useRef(null);

  const [getUserToken,setUserToken] = useState(store.get("shipper").access_token);
  const [getData, setData] = useState({
    name: "",
    phone: "",
    access_token: "",
    fica_documents:[]
  });
  const [getProfile,setProfile] = useState({name:'',email:'',phone:'',ficaimage:'',password:''})
  const [getApiProfile,setApiProfile] = useState(null);
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });

const handleChangeProfile = (e) => {

    const { name, value } = e.target;
    console.log(name,value);
    setProfile(prevState => ({ ...prevState, [name]: value }));
};

const handleChangeImage = (e) => {
  // console.log(reftruck);
  const timestamp = new Date();
  const filename =  timestamp + ownref.current.files[0].name;
  Storage.put(filename, ownref.current.files[0],{}).then(resp => {
    // console.log(e.target.name, resp.key);
    setProfile(prevState => ({ ...prevState, [e.target.name]: resp.key }));
    }).catch(err => {
      console.log(err);
    });

}


  const GetProfileData = () => {
    let shipperRes = store.get("shipper");
    
    instance.get('/profile')
      .then((res)=> {
        setApiProfile(res.data.data);
        // store.set('user',res.data.data)
        console.log('Get Profile',res.data.data);
      })
      .catch(() => {
      
    })
    setProfile({name:shipperRes.name,email:shipperRes.email,phone:shipperRes.phone,ficaimage:''})


  }

  useEffect(() => {
    // Get current user
    let shipperRes = store.get("shipper");
    // console.log('shipper',shipperRes);
    if (shipperRes !== undefined) {
      setData(shipperRes);
      GetProfileData();
      

      // setProfile({name:'',email:'',phone:'',})

      setRefData({
        company_name_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].company_name
            : "",
        description_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].description
            : "",
        phone_number_1:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[0].phone
            : "",
        company_name_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].company_name
            : "",
        description_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].description
            : "",
        phone_number_2:
          shipperRes.references && shipperRes.references.length
            ? shipperRes.references[1].phone
            : "",
      });
    }
  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  // const UpdateProfile = (e) => {
  //   // e.preventDefault();
  //   console.log('getProfile',getProfile);


  // }



  const formSubmit = (e) => {
    // e.preventDefault();
    e.preventDefault();
    setError({});
    let errors = {};

    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!getRefData.phone_number_1) {
      errors.phone_number_1 = "This field is required";
    } else if (!re.test(getRefData.phone_number_1)) {
      errors.phone_number_1 = "Invalid phone format";
    }

    if (!getRefData.company_name_1) {
      errors.company_name_1 = "This field is required";
    } else if (getRefData.company_name_1.length > 40) {
      errors.company_name_1 = "Company name must be less than 40 characters";
    }

    if (!getRefData.phone_number_2) {
      errors.phone_number_2 = "This field is required";
    } else if (!re.test(getRefData.phone_number_2)) {
      errors.phone_number_2 = "Invalid phone format";
    }

    if (!getRefData.company_name_2) {
      errors.company_name_2 = "This field is required";
    } else if (getRefData.company_name_2.length > 40) {
      errors.company_name_2 = "Company name must be less than 40 characters";
    }

    if (
      errors.phone_number_1 ||
      errors.phone_number_2 ||
      errors.company_name_1 ||
      errors.company_name_2
    ) {
      setError(errors);
    } else {
      let refShipperData = {
        references: [
          {
            company_name: getRefData.company_name_1,
            description: getRefData.description_1,
            phone: getRefData.phone_number_1,
          },
          {
            company_name: getRefData.company_name_2,
            description: getRefData.description_2,
            phone: getRefData.phone_number_1,
          },
        ],
      };

      let ship_access_token = store.get("shipper_access_token");
      // console.log("access_token", ship_access_token);
      axios({
        method: "post",
        url: `${io.baseURL}/api/v1/shipper/update-references`,
        data: refShipperData,
        headers: {
          Authorization: `Bearer ${ship_access_token}`,
        },
      })
        .then(function (response) {
          if (response.data.type === "success") {
            store.remove("shipper");
            store.set("shipper", response.data.data);
            toast.info(response.data.message, {
              toastId: customId,
            });
          } else {
            toast.info(response.data.message, {
              toastId: customId,
            });
          }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            toastId: customId,
          });
        });
    }
  };
  return (
    <>
      <div>
        <AuthHeader></AuthHeader>
        {/* Hero section */}
        <DashboardIcons UserToken={getUserToken} UserRole='shipper' />
        {/* Hero section */}
        
        {/* Shipper features section */}
        <section className="edit-details">
          <div className="container">
            <div className="row content">
              <div className="col-12 nav-tab-bar">
                <div className="tabs-nav">
                  <nav className="navbar navbar-expand-lg p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarTogglerDemo01"
                      aria-controls="navbarTogglerDemo01"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >

                      <img
                        className="pr-2"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icons/bars.svg"
                        }
                        alt
                      />
                      Menu
                    </button>
                    <div
                      className="collapse navbar-collapse nav-tabs"
                      id="navbarTogglerDemo01"
                    >
                      <div className="nav" id="nav-tab" role="tablist">
                        <a
                          className="nav-link active"
                          id="nav-profile-tab"
                          data-toggle="tab"
                          href="#nav-profile"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="true"
                        >
                          <i className="user-small">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/user-small.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit profile
                        </a>
                        <a
                          className="nav-link"
                          id="nav-references-tab"
                          data-toggle="tab"
                          href="#nav-references"
                          role="tab"
                          aria-controls="nav-references"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Edit references
                        </a> 
                        <a
                          className="nav-link"
                          id="nav-password-tab"
                          data-toggle="tab"
                          href="#nav-password"
                          role="tab"
                          aria-controls="nav-references"
                          aria-selected="false"
                        >
                          <i className="link-chain">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/link-chain.svg"
                              }
                              alt
                            />
                          </i>{" "}
                          Update password
                        </a>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-12">
                <div className="tab-content" id="nav-tabContent">
                  {/* Edit Profile Tab Start  */}
                  <div
                    className="tab-pane fade edit-profile active show"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {(getApiProfile == null) ? <Loader status={true} ></Loader> : <UpdateProfile Apidata={getApiProfile} ></UpdateProfile>  }
                  
                  </div>

                  {/* Edit Profile Tab End  */}

                  {/* Edit Ref Tab Start   */}

                  <div
                    className="tab-pane fade edit-references"
                    id="nav-references"
                    role="tabpanel"
                    aria-labelledby="nav-references-tab"
                  >
                     {(getApiProfile == null) ? <Loader status={true} ></Loader> : <UpdateRef Apidata={getApiProfile} ></UpdateRef>  }
                  
                  </div>

                  {/*  Edit  Refrences Tab End   */}

                  {/*  Update Password Tab Start   */}
                  <div
                    className="tab-pane fade edit-password "
                    id="nav-password"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <UpdatePassword></UpdatePassword>
                   
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        
         {/* Modals delete doc */}
         <div
          className="modal fade"
          id="deleteDocModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
             
                >
                  Are you sure want to remove this doc ?
                </h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn inset-btn small">
                  Yes delete
                </button>
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="after-footer">
          <div className="container after-footer">
            <small>© All rights reserved | Copyright 2022</small>
            <ul className="extra-links">
              <li>
                <a href="#">Privacy policy</a>
              </li>
              <li>
                <a href="#">Terms of service</a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
