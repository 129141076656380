import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Footer } from "../../partials/Public/Footer";
import { Header } from "../../partials/Public/header";
function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
    <Header></Header>


    {/* Vehicle owner features section */}
    <section className=" vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <div className="row features">
              <div className="col-12">
                <div className="heading  d-flex align-items-center justify-content-center">
                 
                 <u>
                    <h3>Cookies Policy for Mi-Load </h3>
                 </u>
                  

                </div>
              </div>
              <div className="col-12 col-md-12">
                            

                  <p>This Cookies Policy explains what Cookies are and how ‘We’ use them. You should read this policy so ‘You’ can understand what type of cookies ‘We’ use, or the information ‘We’ collect using Cookies and how that information is used.</p>

                  <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about ‘You’ may be linked to the information stored in and obtained from Cookies. For further information on how ‘We’ use, store and keep your personal data secure, see our Privacy Policy.</p>

                  <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies we use.</p>


                  <h3>Interpretation and Definitions</h3>

                  <h5>Interpretation</h5>

                  <p>The words in which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>


                  <h3>Definitions</h3>

                  <p>For this Cookies Policy:</p>
                  <ul>
                      <li>
                        
                            <strong>Company </strong> (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to<strong>Mi-load.</strong> 

                              <strong>Cookies</strong> means small files that are placed on Your computer, mobile device, or any other device by a website, containing details of your browsing history on that website among its many uses.


                              <strong>Website / App</strong> refers to Mi-Loadfrom <hrf>www.mi-load.co.za</hrf>or the app on google / apple play store

                              <strong>You</strong>mean the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.

                              <h5>The use of the Cookies</h5>


                              <p>We use cookies, web beacons, and other similar technologies, including from third-party partners such as Google and Facebook, for measurement services, better targeting advertisements, and marketing purposes. This takes place from the Sites when you download or install an MI-LoadApp, and in some of our advertisements on third-party websites. These cookies, web beacons, and other similar technologies allow us to display our promotional material to you on other sites you visit across the Internet and connect you with more Employers.</p>

                              <p>We also use these cookies, web beacons, and other similar technologies to facilitate the Targeted Ads Program and other advertisements to help us to determine which advertisements users have seen, how many times users have seen a particular advertisement, and on which sites a particular advertisement appeared.</p>

                              <p>We may also use these cookies, web beacons, and other similar technologies on the Sites to understand and inform an Employer about which users saw a particular Job Listing, Company Page, Hiring Event, or other advertisements in which the Employer is identified.</p>

                              <h5>Type of Cookies We Use</h5>

                              <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>

                              <p>We use both session and persistent cookies for the purposes set out below:</p>

                              <ul>
                                  <li>Necessary / Essential Cookies</li>
                              </ul>
                              
                              <p>Type: Session Cookies</p>

                              <p>Administered by: Us</p>

                              <p>Purpose: These cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>

                              <ul>
                                  
                                  <li>Functionality Cookies</li>
                              </ul>


                              <p>Type: Session Cookies</p>

                              <p>Administered by: Us</p>
                              <p>Purpose: These cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>

                              <h5>Tracking Cookies Data</h5>

                              <p>We employ cookies and other tracking technologies to monitor the use of our service, and we maintain some information.</p>

                              <p>Cookies are small files that may contain an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies, including beacons, tags, and scripts, are also employed to collect and track information, as well as to enhance and analyze our Service.</p>

                              <p>You can configure your browser to reject all cookies or to notify you that cookies are being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                              
                              
                              <p>Examples of Cookies we use:</p>

                              <ul>
                                  <li>
                                      <strong>Session Cookies:</strong>We use Session Cookies to operate our Service.

                                      <strong>Preference Cookies:</strong>We use Preference Cookies to remember your preferences and various settings.

                                      <strong>Security Cookies:</strong>We use Security Cookies for security purposes.

                                      <strong>Advertising Cookies:</strong>Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
                                  </li>
                              </ul>

                              <h5>Your Choices Regarding Cookies</h5>
                              
                              
                              <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>


                              <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>


                              <p>If You'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>

                              <ul>
                                  <li>For the Chrome web browser, please visit this page from Google:<hrf>https://support.google.com/accounts/answer/32050</hrf></li>

                                  <li>For the Internet Explorer web browser, please visit this page from Microsoft: <hrf>http://support.microsoft.com/kb/278835</hrf></li>

                                  <li>For the Firefox web browser, please visit this page from Mozilla: <hrf>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</hrf></li>


                                  <li>For the Safari web browser, please visit this page from Apple:<hrf>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</hrf></li>



                              </ul>

                              <p>For any other web browser, please visit your web browser's official web pages.</p>








                      </li>
                  </ul>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
    <Footer></Footer>
  </div>
  
  );
}

export default App;
