import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import { useEffect } from "react";
import { Header } from "../partials/Public/header";
import { Footer } from "../partials/Public/Footer";

function App() {

  const loadImages = () => {
    // console.log('Hello');
    Storage.list("")
      .then((files) => {
        // console.log(files);
        // setFiles(files);
      })
      .catch((err) => {
        // console.log(err);
      });    
  }

  useEffect(()=> {
    // loadImages();
  },[])

  return (
    <div>
    <Header></Header>
    {/* Hero section */}
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 hero-left">
              <img
              src={
                process.env.PUBLIC_URL +
                "assets/images/hero-background (1).png"
              }
              alt=""
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 hero-right">
            <h1><i className="map-pointer" /> Logistics made simple</h1>
            <p>The ultimate solution for shippers and transporters in the grain and oilseed industry. Our cutting-edge internet software seamlessly connects you with reliable partners, ensuring efficient and cost-effective transportation of your goods. With our user-friendly platform and extensive network, trust Mi-Load to simplify your logistics needs and maximize your business growth. Experience the power of seamless integration and join our community today!</p>
              <Link to="/Signup" className="underline-button ">Continue as Shipper <i className="arrow-right-faded" />  </Link>
          </div>
        </div>
      </div>
    </section>
    {/* Shipper features section */}
    <section className="bg-orange shipper-features-section">
      <div className="container shipper-features">
        <div className="row">
          <div className="col-12 col-md-3 left-image">
              <img
              src={
                process.env.PUBLIC_URL +
                "assets/images/map-illustration (1).png"
              }
              alt=""
            />
          </div>
          <div className="col-12 col-md-9">
            <div className="row features">
              <div className="col-12">
                <div className="heading">
                  <h3>Are you a shipper?</h3>
                  <p>Ship and track your loads in a few steps</p>
                </div>
                <div className="signup-button">
                  <Link to="/Signup" className="underline-button">Signup as shipper</Link>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="stack-boxes" />
                  <hr />
                  <h4>Add load job</h4>
                  <p>Easily add new load jobs to find suitable transporters</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="truck-outline" />
                  <hr />
                  <h4>Pick a transporter</h4>
                  <p>Interested transporters will apply for the job and you can pick one</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="pointer-outline" />
                  <hr />
                  <h4>Track live location</h4>
                  <p>You can easily track live location of driver who picked up your load</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Transporter features section */}
    <section className="transporter-features-section">
      <div className="container transporter-features">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <h3>Mi-Load for transport companies</h3>
              <p>Now it is easy for you to find jobs according to your preference</p>
            </div>
            <div className="signup-button">
              <Link to="/Signup" className="underline-button">Signup as Transporter    </Link>
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="row features">
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="register-user" />
                  <hr />
                  <h4>Register on Mi-load</h4>
                  <p>Get registered on our platform and upload documents for authenticity</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="search" />
                  <hr />
                  <h4>Search for jobs</h4>
                  <p>Apply filters and search for jobs according to your preference, place genuine bid rates</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="steering" />
                  <hr />
                  <h4>Assign truck &amp; drivers</h4>
                  <p>Assign a truck and a driver to the job according to the need of job</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 right-image">
              <img
              src={
                process.env.PUBLIC_URL +
                "assets/images/transport-truck (1).png"
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    {/* Vehicle owner features section */}
    <section className="bg-grey vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-12 col-md-3 left-image">
              <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            />
          </div>
          <div className="col-12 col-md-9">
            <div className="row features">
              <div className="col-12">
                <div className="heading">
                  <h3>Mi-Load for individual vehicle owners</h3>
                  <p>Individual vehicle owners can also register and find work through our platform</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="register-user" />
                  <hr />
                  <h4>Register on Mi-load</h4>
                  <p>Get registered on our platform and upload documents for authenticity</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="truck-outline" />
                  <hr />
                  <h4>Add truck details</h4>
                  <p>Add your truck details and upload your license and other documents for authenticity</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <i className="search" />
                  <hr />
                  <h4>Find jobs</h4>
                  <p>You will get a lot of filters to search for jobs according to your convenience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
    <Footer/>
  </div>
  
  );
}

export default App;
