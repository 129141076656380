import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import SideNavBar from "./admin-navbar";
import FooterAdminBottom from "./admin-footer";
const customId = "custom-id-yes";

function App() {
  const [getData, setData] = useState({
    name: "",
    phone: "",
    access_token: "",
  });
  const navigate = useNavigate();
  const [getRefData, setRefData] = useState({
    company_name_1: "",
    description_1: "",
    phone_number_1: "",
    company_name_2: "",
    description_2: "",
    phone_number_2: "",
  });

  useEffect(() => {
    // Get current user
    let shipperRes = store.get("shipper");
    if (shipperRes !== undefined) {
      setData(shipperRes);
    }
  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setRefData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    let refShipperData = {
      references: [
        {
          company_name: getRefData.company_name_1,
          description: getRefData.description_1,
          phone: getRefData.phone_number_1,
        },
        {
          company_name: getRefData.company_name_1,
          description: getRefData.description_2,
          phone: getRefData.phone_number_1,
        },
      ],
    };
    // console.log("@@@@@@@@@@@@", getData.access_token);
    // console.log("refShipperData", refShipperData);
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/company/complete-onboarding`,
      data: refShipperData,
      headers: {
        Authorization: `Bearer ${getData.access_token}`,
      },
    })
      .then(function (response) {
        // console.log("🪀🪀🪀🪀🎴", response.data);
        if (response.data.type === "success") {
          // store.remove("signIn");
          // store.set("signIn", response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          // setTimeout(() => {
          //   navigate("/ShipperDashboard");
          // }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // // console.log(error.response.status);
        // // console.log(error.response.headers);
        // console.log("error", error);
      });
  };
  return (
    <>
      <div>
        <div>
        <SideNavBar/>
          <section className="admin-main-section sidebar-padding">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row quick-view-cards admin-dash">
                    <div className="col-6 col-md-3">
                      <div className="card">
                        <i className="time-clock" />
                        <hr />
                        <h3>24</h3>
                        <h5>Active requests</h5>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="card">
                        <i className="truck" />
                        <hr />
                        <h3>12</h3>
                        <h5>Ready to pick</h5>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="card">
                        <i className="on-route" />
                        <hr />
                        <h3>5</h3>
                        <h5>On route</h5>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="card">
                        <i className="check-completed" />
                        <hr />
                        <h3>76</h3>
                        <h5>Completed</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="brief-details">
                    <div className="header">
                      <h5 className="text-orange">Recently joined shippers</h5>
                      <a href="#" className="btn orange x-small font-weight-normal">view all {">"}</a>
                    </div>
                    <div className="small-table">
                    <table className="records">
                      <tr>
                        <td> <span className="h6">US Seeds</span></td>
                        <td>
                          <small className="text-orange">+90 879336897967</small>
                        </td>
                        <td className="text-right">
                        <small className="text-orange">pending</small>
                        </td>
                      </tr>
                      <tr>
                        <td> <span className="h6">US Seeds</span></td>
                        <td>
                          <small className="text-orange">+90 879336897967</small>
                        </td>
                        <td className="text-right">
                        <small className="text-success">verified</small>
                        </td>
                      </tr>
                      <tr>
                        <td> <span className="h6">US Seeds</span></td>
                        <td>
                          <small className="text-orange">+90 879336897967</small>
                        </td>
                        <td className="text-right">
                        <small className="text-orange">pending</small>
                        </td>
                      </tr>
                      <tr>
                        <td> <span className="h6">US Seeds</span></td>
                        <td>
                          <small className="text-orange">+90 879336897967</small>
                        </td>
                        <td className="text-right">
                        <small className="text-success">verified</small>
                        </td>
                      </tr>
                      <tr>
                        <td> <span className="h6">US Seeds</span></td>
                        <td>
                          <small className="text-orange">+90 879336897967</small>
                        </td>
                        <td className="text-right">
                        <small className="text-orange">pending</small>
                        </td>
                      </tr>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer */}
         <FooterAdminBottom/>
        </div>
      </div>

      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
