import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
import { configureAmplify, SetS3Config } from "../../../aws-s3-doc/server";
import  { Storage } from 'aws-amplify';
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../partials/loader";
import S3Bucket from "../../../../services/S3bucket";


global.Buffer = global.Buffer || require("buffer").Buffer;

const customId = "custom-id-yes";

function App() {
  const ref = useRef(null);

  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImage1, setShowImage1] = React.useState(false);
  const [isloading, setloading] = React.useState(false);
  const [image1, setImage1] = useState(null);
  const [files, setFiles] = useState();
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState();
  const [data, setData] = useState({
    company_name: "",
    phone_number: "",
    c_password: "",
    password: "",
  });
  const fileInput = useRef();
  const delete1 = () => {
    setImage1("");
    setShowImage1(false);
  };




  const handleFileLoad = (event) => {
      setImage1(displayImage(event));
      setShowImage1(true);
      const timestamp = new Date();
      const filename =  timestamp + ref.current.files[0].name;

      const params = {
        ACL: 'public-read',
        Body: ref.current.files[0],
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: filename
    };


      S3Bucket.putObject(params,(err,data) => {
        if (err) {
          console.error('Error uploading object:', err);
        } else {
          setFiles(params.Key)
        }
      })
    

      // Storage.put(filename, ref.current.files[0], {
      //     progressCallback: (progress) => {
      //       setProgress(Math.round((progress.loaded / progress.total) * 100) + "%");
      //       setTimeout(() => { setProgress() }, 1500);
      //     }
      //   }).then(resp => {
      //         // console.log(resp.key);
      //         setFiles(resp.key)
      //   }).catch(err => {
      //     console.log(err);
      //   });
  }



  // const onImageChange1 = (event) => {
  //   setImage1(displayImage(event));
  //   setShowImage1(true);
  //   const file = event.target.files[0];
  //   // console.log("dddddd",file);

  
  // };


  function displayImage(event) {
    if (event.target.files && event.target.files[0]) {
      return URL.createObjectURL(event.target.files[0]);
    }
  }


  function validateForm() {
    return (
      data.company_name.length > 0 &&
      data.phone_number.length > 0 &&
      data.password.length > 0 &&
      data.c_password.length > 0
    );
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    let getStoreData = store.get("signUp");
    setError({});
    let errors = {};
    if (!data.password) {
      errors.password = "This field is required";
    } else if (data.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }

    if (!data.c_password) {
      errors.c_password = "This field is required";
    } else if (data.c_password.length < 4) {
      errors.c_password = "Confirm password must be more than 4 characters";
    }
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!data.phone_number) {
      errors.phone_number = "This field is required";
    } else if (!re.test(data.phone_number)) {
      errors.phone_number = "Invalid phone format";
    }

    if (!data.company_name) {
      errors.company_name = "This field is required";
    } else if (data.company_name.length > 40) {
      errors.company_name = "Company name must be less than 40 characters";
    }

    if (
      errors.password ||
      errors.c_password ||
      errors.phone_number ||
      errors.company_name
    ) {
      setError(errors);
    } else {
      let newPass = "";
      if (data.c_password == data.password) {
        newPass = data.c_password;
      } else {
        toast.info("Password did not match", {
          toastId: customId,
        });
      }
      let shipperData = {
        company_name: data.company_name,
        phone: data.phone_number,
        password: newPass,
        otp_token: getStoreData.otp_token,
        device_id: "22te6ge374y38y3uf834y3u89ryu48f",
        device_type: "1",
        device_token: "q2w3e4er5t6y7u8i9o0m,m",
        fica_documents: [files],
      };

      setloading(true);

      axios({
        method: "post",
        url: `${io.baseURL}/api/v1/auth/register/shipper`,
        data: shipperData,
      })
        .then(function (response) {
      setloading(false);

          if (response.data.type === "success") {
            store.remove("shipper");
            store.remove("AddReferences");
            store.set("user", response.data.data);
            store.set("role", response.data.data.role);
            store.set("AddReferences", response.data.data);
            toast.info(response.data.message, {
              toastId: customId,
            });
            setTimeout(() => {
              if (response.data.data.role == 1) {
                navigate("/AddReferences");
              }
            }, 1000);
          } else {
            toast.error("Something went wrong", {
              toastId: customId,
            });
          }
          return false;
        })
        .catch(function (error) {
          setloading(false);
          toast.error(error.response.data.message, {
            toastId: customId,
          });
          // console.log(error);
        });
    }
  };
  return (
    <>
    <Loader status={isloading} ></Loader>
      <section className="auth complete-shipper-profile">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-7">
              <div className="form-container">
                <div className="title pb-4">
                  <Link to="/ChooseRole">
                    <button className="btn small back-button">
                      <i className="back-cheveron pr-2" />
                      back
                    </button>
                  </Link>
                  <h3>Complete Shipper's profile</h3>
                </div>
                <form
                  onSubmit={formSubmit}
                  className="form-contol complete-profile-form"
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Company name"
                      onChange={InputEvent}
                      value={data.company_name}
                      name="company_name"
                      id="company_name"
                      minLength="2"
                      maxLength="40"
                      error={Error.company_name ? true : false}
                      required
                    />
                    {Error.company_name && <small>{Error.company_name}</small>}
                    <label htmlFor="company_name" />
                  </div>
                  <div className="position-relative">
                    <input
                      type="number"
                      placeholder="Phone number"
                      id="phone_number"
                      onChange={InputEvent}
                      value={data.phone_number}
                      error={Error.phone_number ? true : false}
                      name="phone_number"
                      minLength="10"
                      required
                    />
                    {Error.phone_number && <small>{Error.phone_number}</small>}
                    <label htmlFor="phone_number" />
                  </div>
                  <div className="position-relative file-container">
                    <input
                      type="file"
                      id="select_files"
                     ref={ref}
                      // onChange={handleClick}
                      onChange={(event) => handleFileLoad(event)}
                      name="select_files"
                      title=""
                      placeholder=""
                    />
                    { (progress == null) ? '' : 
                    
                    <div style={{width: "100%"}} class="progress" >
                      <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${progress}`,backgroundColor:'#e25828'}} role="progressbar"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      {progress}
                      </div>
                    </div>
                    }


                    <label htmlFor="select_files" className>
                      Upload FICA docs  
                      
                    </label>

                    <span className="file_name">No file choosen</span>
                    {showImage1 ? (
                      <div class="uploads-preview popup-gallery">
                        <a href="#!">
                          <img src={image1} alt="preview image" />{" "}
                          <i onClick={delete1} class="delete-icon"></i>
                        </a>
                      </div>
                    ) : null}
                    {/* <i class="delete-icon"></i>
                          <i class="open-eye"></i>
                          <div class="preview-image">
                              <img src="assets/images/sample-doc (1).png" alt="">
                          </div> */}
                    {/* <div class="uploads-preview popup-gallery">
                              <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                              <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                              <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                          </div> */}
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Password"
                      id="password"
                      onChange={InputEvent}
                      value={data.password}
                      name="password"
                      error={Error.password ? true : false}
                      minLength="4"
                      required
                    />

                    {Error.password && <small>{Error.password}</small>}
                    <label htmlFor="password" />
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Confirm password"
                      id="c_password"
                      onChange={InputEvent}
                      value={data.c_password}
                      name="c_password"
                      error={Error.c_password ? true : false}
                      minLength="4"
                      required
                    />
                    {Error.c_password && <small>{Error.c_password}</small>}

                    <label htmlFor="c_password" />
                  </div>
                  <div className="signed-in accept-conditions">
                    <div className="checkbox-container">
                      <input type="checkbox" name id="accept_tc" />
                      <span className="checkmark" />
                      <label htmlFor="accept_tc">
                        Accept <a href="#">terms &amp; conditions</a>
                      </label>
                    </div>
                  </div>
                  <div className="submit-button">
                    <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
