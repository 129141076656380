import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    type: "reset-password",
  });

  function validateForm() {
    return data.email.length > 0;
  }
  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    // // console.log("=======", data);
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/auth/email-verification`,
      data: data,
    })
      .then(function (response) {
      //  // console.log("🪀🪀🪀🪀🎴", response.data);
        if (response.data.type === "success") {
          store.remove("forgotPass");
          store.set("forgotPass", response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          setTimeout(() => {
            navigate("/EnterOtp");
          }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // // console.log(error.response.status);
        // // console.log(error.response.headers);
        // console.log("error", error);
      });
  };
  return (
    <>
      <section className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                    alt
                  />
                </div>
              </div>
              <div className="form-container">
                <div className="title pb-4">
                  <h3>Enter email</h3>
                  <Link to="/">
                    <button className="btn small back-button">
                      <i className="back-cheveron" />
                    </button>
                  </Link>
                </div>
                <p>
                  Enter email, we will send OTP (One Time Password) for email
                  verification
                </p>
                <form
                  onSubmit={formSubmit}
                  className="form-contol forgot-password-email"
                >
                  <div className="position-relative">
                    <input
                      type="email"
                      placeholder="Enter email"
                      onChange={InputEvent}
                      value={data.email}
                      required
                      name="email"
                      id="email"
                    />
                    <label htmlFor="email" />
                  </div>
                  <div className="submit-button">
                    <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
