import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  function validateForm() {
    return data.email.length > 0 && data.password.length > 0;
  }
  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  const formSubmit = (e) => {
    e.preventDefault();
     //// console.log("=======", data);
    //navigate("/Admin-Dashboard");
    axios({
      method: "post",
      url: `${io.baseURL}/api/admin/sadmin-login`,
      data: data,
    })
      .then(function (response) {
        // console.log("🪀🪀🪀🪀🎴", response.data);
        if (response.data.type === "success") {
          store.remove("SuperAdminLogin");
          //store.remove("forgotPass");
          // console.log("##################",response.data.data);
          store.set("SuperAdminLogin", response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          setTimeout(() => {
            navigate("/Admin-Dashboard");
          }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // console.log("<====== error =====>", error);
      });
  };

  return (
    <div>
      <section className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                      alt="no logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="form-container signup">
                <div className="title">
                  <h3>Admin Login</h3>
                </div>
                <form onSubmit={formSubmit} className="form-contol login-form">
                  <div className="position-relative">
                    <input
                      type="email"
                      placeholder="Enter email"
                      onChange={InputEvent}
                      value={data.email}
                      required
                      name="email"
                      id="email"
                    />
                    <label htmlFor="email" />
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Enter password"
                      onChange={InputEvent}
                      value={data.password}
                      required
                      name="password"
                      id="password"
                    />
                    <label htmlFor="password" />
                  </div>
                  <div className="submit-button">
                    {/* <link to="/EnterOtp" /> */}
                    <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
}
export default App;
