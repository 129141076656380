import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const [Error, setError] = useState({});
  const [data, setData] = useState({
    email: "",
    message: "",
    name: "",
    phone: "",
  });
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  function validateForm() {
    return (
      data.email.length > 0 &&
      data.message.length > 0 &&
      data.name.length > 0 &&
      data.phone.length > 0
    );
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    // console.log("@@@@@@@@@@@", data);
    setError({});
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!data.email) {
      errors.email = "This field is required";
    } else if (!regex.test(data.email)) {
      errors.email = "Invalid email format";
    }

    if (!data.message) {
      errors.message = "This field is required";
    } else if (data.message.length < 2) {
      errors.message = "message must be more than 2 characters";
    }

    if (!data.name) {
      errors.name = "This field is required";
    } else if (data.name.length < 2) {
      errors.name = "name must be more than 2 characters";
    }

    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!data.phone) {
      errors.phone = "This field is required";
    } else if (!re.test(data.phone)) {
      errors.phone = "Invalid phone format";
    }

    if (errors.email || errors.message || errors.name || errors.phone) {
      setError(errors);
    } else {
      axios({
        method: "post",
        url: `${io.baseURL}/api/v1/query/add`,
        data: data,
      })
        .then(function (response) {
          // console.log("@@@@@@@@@@@@2",response.data.type);
          if (response.data.type === "success") {
            toast.info(response.data.message, { toastId: customId });
            setData({
              email: "",
              message: "",
              name: "",
              phone: "",
            });
          }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            toastId: customId,
          });
        });
    }
  };
  return (
    <div>
      <header className="full-header">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                alt=""
              />{" "}
            </Link>
          </div>
          <nav>
            <ul>
              <li>
                {" "}
                <Link
                  to="/Signup"
                  className="btn transparent large font-weight-normal"
                >
                  Signup{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/Login"
                  className="btn orange large font-weight-normal"
                >
                  Login{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/Login"
                  className="btn orange large font-weight-normal"
                >
                  Login / Signup
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Vehicle owner features section */}
      <section className=" vowner-features-section">
        <div className="container vowner-features">
          <div className="row">
            <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
            </div>
            <div className="col-12 col-md-9">
              <div className="row features">
                <div className="col-12">
                  <div className="heading">
                    <h3>Contact Us </h3>
                    <p>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <div className="form-container">
                    <form onSubmit={formSubmit} className=" login-form">
                      <div className="position-relative">
                        <input
                          type="text"
                          placeholder="Enter name"
                          onChange={InputEvent}
                          value={data.name}
                          required
                          error={Error.name ? true : false}
                          name="name"
                          id="name"
                        />
                        {Error.name && <small>{Error.name}</small>}
                        <label htmlFor="name" />
                      </div>
                      <div className="position-relative">
                        <input
                          type="email"
                          placeholder="Enter email"
                          onChange={InputEvent}
                          value={data.email}
                          required
                          error={Error.email ? true : false}
                          name="email"
                          id="email"
                        />
                        {Error.email && <small>{Error.email}</small>}
                        <label htmlFor="email" />
                      </div>
                      <div className="position-relative">
                        <input
                          type="number"
                          placeholder="Enter phone number"
                          onChange={InputEvent}
                          value={data.phone}
                          required
                          error={Error.phone ? true : false}
                          name="phone"
                          id="phone"
                        />
                        {Error.phone && <small>{Error.phone}</small>}
                        <label htmlFor="phone" />
                      </div>
                      <div className="position-relative">
                        <input
                          type="text"
                          placeholder="Enter message"
                          onChange={InputEvent}
                          value={data.message}
                          required
                          error={Error.message ? true : false}
                          name="message"
                          id="message"
                        />
                        {Error.message && <small>{Error.message}</small>}

                        <label htmlFor="message" />
                      </div>

                      <div className="submit-button">
                        <div className="signed-in">
                          {/* <div className="checkbox-container">
                      <input type="checkbox" name id="keep-signin" />
                      <span className="checkmark" />
                      <label htmlFor="keep-signin">Keep me signed in</label>
                    </div>
                    <Link to="/ForgotmessageEmail">forgot message ?</Link> */}
                        </div>
                        <button
                          type="submit"
                          disabled={!validateForm()}
                          className="btn large grey"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />   
      </section>
      {/* Footer */}
      <footer className="site-footer">
        <div className="container footer">
          <div className="row">
            <div className="col-12 col-md-5 small-description">
              <img
                className="footer-logo"
                src={process.env.PUBLIC_URL + "assets/images/logo-white.svg"}
                alt=""
              />
              <small>Logistics Service : Dry-Bulk Grains &amp; Oilseeds</small>
              <hr />
              <small>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet
              </small>
            </div>
            <div className="col-12 col-md-1" />
            <div className="col-12 col-md-3 registration-links">
              <h5 className="heading">Registration</h5>
              <ul className="links">
                <li>
                  {" "}
                  <Link to="/Signup">Register as shipper </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/Signup">Register as transporter</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/Signup">Register as vehicle owner</Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-3 quick-links">
              <h5 className="heading">Quick links</h5>
              <ul className="links">
                <li>
                  <Link to="/AboutUs">About us</Link>
                </li>
                <li>
                  <Link to="/Faq">FAQs</Link>
                </li>
                <li>
                  <Link to="/ConnectSupport">Contact support</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="after-footer">
        <div className="container after-footer">
          <small>© All rights reserved | Copyright 2022</small>
          <ul className="extra-links">
            <li>
              <Link to="/PrivacyPolicy#">Privacy policy</Link>
            </li>
            <li>
              <Link to="/TermAndService">Terms of service</Link>
            </li>
          </ul>
        </div>
      </footer>
    
    </div>
 
 );
}

export default App;
