import React from "react";
// import Logo from "../../../../public/assets/images/mi-admin-logo(1).png";

function FooterAdminBottom() {
  return (
    <>
      <footer className="after-footer admin-dash">
        <div className="container after-footer">
          <small>© All rights reserved | Copyright 2022</small>
          <ul className="extra-links">
            <li>
              <a href="#">Privacy policy</a>
            </li>
            <li>
              <a href="#">Terms of service</a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default FooterAdminBottom;
