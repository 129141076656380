import instance from "../../../context/fetchclient";
import store from "store";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import Loader from "../loader";

const customId = "custom-id-yes";


const TruckList = ()=> {
    const [truckListData, setTruckListData] = useState({});
    const [truckFilterData, settruckFilterData] = useState({});
    const [isloading, setloading] = useState(true);
    useEffect(() =>{
        getTrucksList();
    },[])
    let TruckList = [];
    function getTrucksList(params) {
        // setloading(true);
        instance.get('/company/all-trucks')
          .then(function (response) {
            setloading(false);
            if (response.data.type === "success") {
             // console.log( "@@@@@@@@@@@@response.data.data", response.data.data);
              setTruckListData({ data: response.data.data });
              settruckFilterData(response.data.data);
            } else {
              toast.info(response.data.message, {
                toastId: customId,
              });
            }
          })
          .catch(function (error) {
            setloading(false);
            toast.error(error.response.data.message, {
              toastId: customId,
            });
            
            console.log("error", error);
          });
    }


    const handleTruckSearch = (e) => {
      setloading(true);
      // console.log(e.target.value);
       let filtered = truckListData.data.filter((arrBirds) => {
        return arrBirds.model.includes(e.target.value);
      });
      settruckFilterData(filtered)
      TruckList = filtered;
      setloading(false);


    }


     return ( 
         <>
        <Loader status={isloading} ></Loader>
        <div
            className="tab-pane fade trucks-list"
            id="nav-trucks"
            role="tabpanel"
            aria-labelledby="nav-trucks-tab"
          >
            <div className="col-12">
              <div className="row">
                <div className="col-12 search-and-add">
                  <div className="search-input">
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="search"
                        id="search-truck"
                        onChange={(e) => handleTruckSearch(e)}
                        name="truck_seach"
                      />
                      <label htmlFor="search-truck" />
                      <img
                        className="search-icon"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icons/search-outline.svg"
                        }
                        alt
                      />
                    </div>
                  </div>
                  <Link
                    to="/AddTruck"
                    className="btn flat small add-truck"
                  >
                    <i className="plus">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icons/dashboard-icons/add-truck.svg"
                        }
                        alt
                      />
                    </i>
                    Add truck
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <table
                className="trucks-table"
                border={0}
                cellPadding={0}
                cellSpacing={0}
                width="100%"
              >
                <tbody>
                {truckFilterData.length
                      ? truckFilterData.map((el, key) => (
                        <tr  key={key} className="truck-item">
                        <td>
                          <h5 className="truck-name">
                            {el.truck_manufacturer}
                          </h5>
                        </td>
                        <td>
                          <p className="truck-specs">
                         {el.truck_number}
                          </p>
                        </td>
                        <td>
                          <Link to={`/TruckDetails?${el.truck_id}`} >
                            <span className="truck-reg-no">
                              <i className="eye-open">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/icons/dashboard-icons/eye.svg"
                                  }
                                  alt
                                />
                              </i>
                          {el.model}
                            </span>
                          </Link>
                        </td>
                        <td>
                          <p className="trailers"> {el.trailer_count} trailers</p>
                        </td>
                        <td>
                          <p className="driver-name">
                            Driver : <span>{el.driver_name}</span>
                          </p>
                        </td>
                        <td>
                          <Link to={`/UpdateTruck?${el.truck_id}`} 
                          
                            className="btn small grey edit-button"
                          >
                            <i className="pencil">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/icons/dashboard-icons/pencil.svg"
                                }
                                alt
                              />
                            </i>{" "}
                            Edit
                          </Link>
                        </td>
                        <td>
                          {/* <button
                            className="btn small inset-btn delete-button"
                            data-toggle="modal"
                            data-target="#deleteModal"
                          >
                            <i className="delete">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/icons/trash-orange.svg"
                                }
                                alt
                              />
                            </i>
                          </button> */}
                        </td>
                      </tr>
                    
                        ))
                      : "No Record Found"}
                   <tr className="spacer" /> 
                  
                </tbody>
              </table>
            </div>
          </div></>)
}


export default TruckList;