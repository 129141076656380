import { Link,useNavigate } from "react-router-dom";
import TokenStore from "../../../localstore/TokenStore";


export const AuthHeader = ()=> {
  const navigate = useNavigate();

  const handleLogout = () =>  {
    navigate('/logout');
  }

    return (<><header className="full-header dash-header">
    <div className="container">
      <div className="logo">
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
            alt
          />
        </Link>
      </div>
      <a  data-toggle="modal" data-target="#logout" className="btn transparent large logout">
        <i className="log-out" /> Logout
      </a>
    </div>
  </header>
  <div
          className="modal"
          id="logout"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title underline"
                  id="exampleModalCenterTitle"
                >
                  Are you sure you want to log out?
                </h5>
              </div>
              <div className="modal-footer">
                 {/* <Link to={'/logout'} > */}
                    <button onClick={handleLogout} data-dismiss="modal" type="button" className="btn  inset-btn small">
                    Yes, Logout
                    </button>  
                 {/* </Link> */}
                <button
                  type="button"
                  className="btn orange small"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
  
  </>);
}