import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import io from "../../utils/axios";
import store from "store";
import Buttonloading from "../../partials/Buttonloading";
import Loader from "../../partials/loader";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");
  const [counter, setCounter] = React.useState(59);
  const [isloading, setloadning] = React.useState(false);

  const [data, setData] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
    otp: "",
    otp_token: "",
  });

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   // console.log(timer);
  //   return () => clearInterval(timer);
  // }, [counter]);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:59");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  // const onClickReset = () => {
  //   clearTimer(getDeadTime());
  // }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // console.log("next");
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  function validateForm() {
    return (
      data.digit1.length > 0 &&
      data.digit2.length > 0 &&
      data.digit3.length > 0 &&
      data.digit4.length > 0 &&
      data.digit5.length > 0 &&
      data.digit6.length > 0
    );
  }

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const resendOtp = (e) => {
    e.preventDefault();
    let getStoreData = store.get("signUp");
    setloadning(true);
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/auth/resend-otp`,
      data: { otp_token: getStoreData.otp_token },
    })
      .then(function (response) {
          setloadning(false);
        if (response.data.type === "success") {
          clearTimer(getDeadTime());
          toast.info(response.data.message, {
            toastId: customId,
          });
        } else {
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
        return false;
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error);
      });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    let getStoreData = store.get("signUp");
    let getforgotPass = store.get("forgotPass");
    setloadning(true);
    if (getforgotPass && getforgotPass.otp_token) {
      store.remove("signUp");
    }
    if (getStoreData && getStoreData.otp_token) {
      store.remove("forgotPass");
    }
    data.otp =
      parseInt(data.digit1) +
      "" +
      parseInt(data.digit2) +
      "" +
      parseInt(data.digit3) +
      "" +
      parseInt(data.digit4) +
      "" +
      parseInt(data.digit5) +
      "" +
      parseInt(data.digit6);
    if (getforgotPass && getforgotPass.otp_token) {
      data.otp_token = getforgotPass.otp_token;
    }
    if (getStoreData && getStoreData.otp_token) {
      data.otp_token = getStoreData.otp_token;
    }

    let objData = {
      otp: data.otp,
      otp_token: data.otp_token,
    };
    axios({
      method: "post",
      url: `${io.baseURL}/api/v1/auth/match-otp`,
      data: objData,
    })
      .then(function (response) {
        if (response.data.type === "success") {
          toast.info(response.data.message, {
            toastId: customId,
          });
          setloadning(false);

          // setTimeout(() => {
            if (getforgotPass && getforgotPass.otp_token) {
              navigate("/ResetPassword");
            }
            if (getStoreData && getStoreData.otp_token) {
              navigate("/ChooseRole");
            }
          // }, 1000);
        } else {
          toast.error("Something went wrong", {
            toastId: customId,
          });
          setloadning(false);

        }
        return false;
      })
      .catch(function (error) {
        setloadning(false);
        toast.error(error.response.data.message, {
        

          toastId: customId,
        });
        // console.log(error);
      });
  };
  return (
    <>
    <Loader status={isloading} />
      <section className="auth">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-lg-6">
              <div className="row justify-content-end">
                <div className="col-9 logo">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
                    alt
                  />
                </div>
              </div>
              <div className="form-container enter-otp">
                <div className="title">
                  <h3>Enter OTP</h3>
                  <Link to="/Signup">
                    <button className="btn small back-button">
                      <i className="back-cheveron" />
                    </button>
                  </Link>
                </div>
                <p>
                  Check your email, copy the OTP you have received for
                  verification
                </p>
                <form
                  onSubmit={formSubmit}
                  className="form-contol otp-verification"
                >
                  <div className="position-relative otp-inputs">
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      pattern="[0-1]*"
                      value={data.digit1}
                      required
                      name="digit1"
                      id="digit1"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      maxLength={1}
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="1"
                      autoComplete="off"
                    />
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      value={data.digit2}
                      required
                      name="digit2"
                      id="digit2"
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="2"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      maxLength="1"
                      autoComplete="off"
                    />
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      value={data.digit3}
                      required
                      name="digit3"
                      id="digit3"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      autoComplete="off"
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="3"
                      maxLength="1"
                    />
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      value={data.digit4}
                      required
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      name="digit4"
                      id="digit4"
                      autoComplete="off"
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="4"
                      maxLength="1"
                    />
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      value={data.digit5}
                      required
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      name="digit5"
                      id="digit5"
                      autoComplete="off"
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="5"
                      maxLength="1"
                    />
                    <input
                      type="number"
                      placeholder="-"
                      onChange={InputEvent}
                      value={data.digit6}
                      required
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 1);
                      }}
                      name="digit6"
                      id="digit6"
                      autoComplete="off"
                      onKeyUp={(e) => inputfocus(e)}
                      tabIndex="6"
                      maxLength="1"
                    />
                  </div>
                  <div className="submit-button">
                  <Buttonloading 
                  loading={isloading} 
                  type='submit' 
                  isdisabled={!validateForm()} 
                  classes="btn large grey"  > 
                     Verify OTP 
                  </Buttonloading>
                    {/* <button
                      type="submit"
                      disabled={!validateForm()}
                      className="btn large grey"
                    >
                      Verify OTP
                    </button> */}
                    <a onClick={resendOtp}>
                      Resend OTP <span>{timer}</span>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
  );
}

export default App;
