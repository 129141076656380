import { Link } from "react-router-dom"
import store from "store"

export const Header = () => {

    return (<header className="full-header">
    <div className="container">
      <div className="logo">
        <Link
        to="/"> 
        <img
            src={process.env.PUBLIC_URL + "assets/images/logo.svg"}
            alt=""
          /> </Link>
      </div>
      <nav>
        <ul>
            {
            (store.get('user')) 
            
            ? 
            <>
            <li> <Link to="/login" className="btn orange large font-weight-normal"> Dashboard  </Link></li>
            <li> <Link to="/login" className="btn orange large font-weight-normal"> Dashboard  </Link></li>
            </>
            : 
            <>
                <li> <Link to="/Signup" className="btn transparent large font-weight-normal">Signup  </Link></li>
                <li> <Link to="/Login" className="btn orange large font-weight-normal">Login </Link></li>
                <li><Link to="/Login" className="btn orange large font-weight-normal">Login / Signup</Link></li>
            </>    }
          
        </ul>
      </nav>
    </div>
  </header>)

}