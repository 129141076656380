import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import TokenStore from "../../../localstore/TokenStore";
import Loader from "../loader";
import { ToastContainer, toast } from "react-toastify";
import { get } from "store";
import instance from "../../../context/fetchclient";


const customId = "custom-id-yes";

function UpdatePassword({UserToken,UserRole}) {
  const navigate = useNavigate();
  const [getCount,setCount] = useState([]);



  useEffect(() => {
    console.log('AccessToken',TokenStore.getLocalAccessToken());
    console.log('RefeshToken',TokenStore.getLocalRefreshToken());
  
  },[])

  const [getData,setData]  = useState({current_password:'',new_password:'',confirm_password:''});
  const [isloading,setloadning] = useState(false);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData(prevState => ({ ...prevState, [name]: value }));
  } 

  const handleUpdatePassword = (e) =>  {
    e.preventDefault();
    setloadning(true);
    console.log('data',getData);

    if(getData.new_password === getData.confirm_password){

        instance.post('/change-password',{password:getData.current_password,new_password:getData.new_password})
            .then((res) => {  
                 setloadning(false);
                 toast.success(res.data.message,{ toastId: customId,});
                 setData({current_password:'',new_password:'',confirm_password:''})
                // console.log(res) })
            })
            .catch((err) => {
                setloadning(false);
                toast.error(err.response.data.message,{ toastId: customId,});
            })
    }else{
        toast.error('New password is not matched with confirm password',{ toastId: customId,});
    }
    setloadning(false);






  }


  return (
    <>
      <Loader status={isloading} />
    <div className="col-12 col-md-6">
    <h5>Update password</h5>
    <form onSubmit={handleUpdatePassword} className="form-contol edit-profile-form" >
      <div className="position-relative">
        <label className="font-weight-bold text-muted" >Current Password   <span className="text-danger h4" > * </span> </label>
        <label htmlFor />
        <input
          type="password"
          minLength="4"
          maxLength="40"
          placeholder="Enter Current password"
          required
          onChange={handleChangeInput}
          name="current_password"
          value={getData.current_password}
        />
       
      </div>
      <div className="position-relative">
        <label className="font-weight-bold text-muted" > New Password  <span className="text-danger h4" > * </span>  </label>
        <label htmlFor />
        <input
          type="password"
          minLength="8"
          maxLength="40"
          placeholder="Enter new password"
          required
          onChange={handleChangeInput}
          name="new_password"
          value={getData.new_password}

        />
       
      </div>
      <div className="position-relative">
        <label className="font-weight-bold text-muted" > Confirm new password  <span className="text-danger h4" > * </span> </label>
        <label htmlFor />
        <input
          type="password"
          minLength="8"
          maxLength="40"
          placeholder="Enter new password again"
          required
          onChange={handleChangeInput}
          name="confirm_password"
          value={getData.confirm_password}

        />
       
      </div>

      <div className="submit-button text-center pt-4">
        <button type="submit" className="btn large grey">Update </button>
      </div>
    </form>
    </div></>);
}

export default UpdatePassword;

