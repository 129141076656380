import axios from "axios";

import TokenStore from "../localstore/TokenStore";
import store from "store";
import { useNavigate } from "react-router-dom";

const instance = axios.create({
  baseURL: `https://api.mi-load.com/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenStore.getLocalAccessToken();
    // console.log('token',token)
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
      console.log('err',err);
    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/refresh-token", {
            refresh_token: TokenStore.getLocalRefreshToken(),
          });
          // console.log(TokenStore.getLocalRefreshToken());
          const { token } = rs.data.data;
          console.log('token updated ');
          TokenStore.updateLocalAccessToken(token);
          // console.log(token);
          return instance(originalConfig);
        } catch (_error) {
          console.log('Auth Failer Retry');
          store.clearAll();
          window.location.reload()
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;