import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Footer } from "../../partials/Public/Footer";
import { Header } from "../../partials/Public/header";

function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
   
    <Header></Header>

    {/* Vehicle owner features section */}
    <section className=" vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <div className="row features">
              <div className="col-12">
                <div className="heading d-flex align-items-center justify-content-center">
                  <u>

                  <h3>Frequently Asked Questions</h3>
                  </u>
                 
                </div>
              </div>
              <div className="col-12 col-md-12">
              <hr />
              <ol type="1" >
                <li>
                  <p className="question">What is Mi-Load ?</p>
                  <p className="answer">Mi-Load is is platform that connects transporters and their drivers with shippers.</p>
                </li>
                <li>
                  <p className="question">How does it work ?</p>
                  <p className="answer">Shipper: Register as a shipper and post jobs available </p>
                  <p className="answer">Transporter: Register as a transporter , register trucks and drivers apply for jobs posted by shippers </p>
                </li>
                <li>
                  <p className="question">Who does Mi-Load Serve ?</p>
                  <p className="answer">Shippers, Transporters and their drivers and Owner drivers</p>
                </li>
                <li>
                  <p className="question">How does payment work? </p>
                  <p className="answer">Shipper: Shipper pays 7 working days from receipt of Invoice and Statement from Mi-Load</p>
                  <p className="answer">Transporter: Gets paid 10 working days after Mi-Load receive correct Invoices and Statements </p>
                </li>
                <li>
                  <p className="question">Who does transporter Invoice ? </p>
                  <p className="answer">Transporter invoice Mi-load.</p>
                </li>
                <li>
                  <p className="question">Transporter invoice Mi-load.</p>
                  <p className="answer">Mi-Load will invoice shipper through loads done on the platform.</p>
                </li>
                <li>
                  <p className="question">Will my load be covered in case of damage or loss?</p>
                  <p className="answer">Yes, all transporters registering to use the platform must have Goods In-transit Insurance, if this insurance lapsed or need to be renewed the transporters account will be suspended until all updated documents have been received. </p>
                </li>
                <li>
                  <p className="question">Can shipper post loads available for any commodity? </p>
                  <p className="answer">No, currently the platform is designed to accommodate the grains & oilseeds market. Acceptable commodities include but is not limited to Yellow Maize, White Maize, Wheat, Soya Beans, Sunflower etc.  </p>
                </li>
                <li>
                  <p className="question">Can shipper load from any storage facility?</p>
                  <p className="answer">Yes, it must be from a registered storage facility with weighbridge and ticket system.</p>
                </li>
                <li>
                  <p className="question">Can shipper post multiple loads?</p>
                  <p className="answer">Yes multiple loads can be posted but must be deleted or amended if the shipment date has past.</p>
                </li>
                <li>
                  <p className="question">Is live tracking available? </p>
                  <p className="answer">Yes once a trip start, in some areas network is a problem but live chat for an update on location is available until network gets restored.</p>
                </li>
                <li>
                  <p className="question">Does Mi-Load own any trucks?  </p>
                  <p className="answer">No.</p>
                </li>
              </ol>



              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
    <Footer></Footer>
  </div>
  
  );
}

export default App;
