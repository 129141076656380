import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import { Document, Page } from 'react-pdf';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../partials/loader";
import http from "../../../../context/http";
import Buttonloading from "../../../partials/Buttonloading";
import  { Storage } from 'aws-amplify';
import S3Bucket from "../../../../services/S3bucket";


//import { uploadFile } from "react-s3";
const customId = "custom-id-yes";

function App() {
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const [isloading, setloadning] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [locationRead, setLocationRead] = useState(true);
  const [status, setStatus] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [FicaImage, setFicaImage] = React.useState();
  const [License, SetLicense] = React.useState();
  const [showImage1, setShowImage1] = React.useState(false);
  const [showImage2, setShowImage2] = React.useState(false);
  const [data, setData] = useState({
    company_name: "",
    phone: "",
    c_password: "",
    c_location: "",
    password: "",
  });
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const [progress, setProgress] = useState();
  const ref = useRef(null);
  const ref1 = useRef(null);




  const handleFicaImage = (event) => {
    setImage1(displayImage(event));
    setShowImage1(true);
    // console.log('event',ref);
    const timestamp = new Date();
    console.log('File Type ',ref.current.files[0].type)
    const filename =  timestamp + ref.current.files[0].name;

    const params = {
      ACL: 'public-read',
      Body: ref.current.files[0],
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: filename
  };

    S3Bucket.putObject(params,(err,data) => {
      if (err) {
        console.error('Error uploading object:', err);
      } else {
        setFicaImage(params.Key)
      }
    })
  }
    // Storage.put(filename, ref.current.files[0], {
    //     progressCallback: (progress) => {
    //       setProgress(Math.round((progress.loaded / progress.total) * 100) + "%");
    //       setTimeout(() => { setProgress() }, 1500);
    //     }
    //   }).then(resp => {
    //         console.log('Fica set ',resp.key);
    //         setFicaImage(resp.key)
    //   }).catch(err => {// console.log(err);
    //   });
  // }

  const handleLicense = (event) => {
    setImage2(displayImage(event));
    setShowImage2(true);

    const timestamp = new Date();
    const filename =  timestamp + ref1.current.files[0].name;


    const params = {
      ACL: 'public-read',
      Body: ref1.current.files[0],
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: filename
  };

    S3Bucket.putObject(params,(err,data) => {
      if (err) {
        console.error('Error uploading object:', err);
      } else {
        SetLicense(params.Key)
      }
    })




  }


  
  const delete1 = () => {
    setImage1("");
    setShowImage1(false);
  };
  const delete2 = () => {
    setImage2("");
    setShowImage2(false);
  };

  const onImageChange1 = (event) => {
    setImage1(displayImage(event));
    setShowImage1(true);
  };
  const onImageChange2 = (event) => {
    setImage2(displayImage(event));
    setShowImage2(true);
  };
  
  function displayImage(event) {
    if (event.target.files && event.target.files[0]) {
      return URL.createObjectURL(event.target.files[0]);
    }
  }


  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const getProfile = () => {
      http.get('/profile')
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let getStoreData = store.get("signUp");
    setError({});
    let errors = {};
    if (!data.password) {
      errors.password = "This field is required";
    } else if (data.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }

    if (!data.c_password) {
      errors.c_password = "This field is required";
    } else if (data.c_password.length < 4) {
      errors.c_password = "Confirm password must be more than 4 characters";
    }
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!data.phone) {
      errors.phone = "This field is required";
    } else if (!re.test(data.phone)) {
      errors.phone = "Invalid phone format";
    }

    if (!data.company_name) {
      errors.company_name = "This field is required";
    } else if (data.company_name.length > 40) {
      errors.company_name = "Company name must be less than 40 characters";
    }

    if (
      errors.password ||
      errors.c_password ||
      errors.phone ||
      errors.company_name
    ) {
      setError(errors);
    } else {
      if (data.c_password == data.password) {
        let transData = {
          otp_token: getStoreData.otp_token,
          company_name: data.company_name,
          company_lng: coordinates.lng ? String(coordinates.lng) : "1.123456",
          company_lat: coordinates.lat ? String(coordinates.lat) : "2.123456",
          company_location: address,
          phone: data.phone,
          password: data.password,
          device_id: "wy3te6ge374y38y3uf834y3u89ryu48f",
          device_type: "1",
          device_token: "q2w3e4er5t6y7u8i9o0m,m",
          fica_documents: [
            FicaImage
          ],
          license_documents: [
            License
          ],
        };
        setloadning(true)

         console.log('transData ------>',transData)
        setloadning(false)

        axios({
          method: "post",
          url: `${io.baseURL}/api/v1/auth/register/transportation-company`,
          data: transData,
        })
          .then(function (response) {
              setloadning(false)
            
            if (response.data.type === "success") {
              store.remove("trans-comapny");
              store.remove("AddReferences");
              store.set("AddReferences", response.data.data);
              store.set("role", response.data.data.role);
              store.set("user", response.data.data);

              toast.info(response.data.message, {
                toastId: customId,
              });
              // setTimeout(() => {
                if (response.data.data.role == 2) {
                  navigate("/AddReferences");
                }
              // }, 1000);
            } else {
              toast.error("Something went wrong", {
                toastId: customId,
              });
            }
            return false;
          })
          .catch(function (error) {
            setloadning(false)

            toast.error(error.response.data.message, {
              toastId: customId,
            });
          });
      } else {

        toast.info("Password did not match", {
          toastId: customId,
        });
      }
    }
  };
  return (
    <>
    <Loader status={isloading} />
      <section className="auth complete-transporter-profile">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-md-7">
              <div className="form-container">
                <div className="title pb-4">
                  <Link to="/ChooseRole">
                    <button className="btn small back-button">
                      <i className="back-cheveron pr-2" />
                      back
                    </button>
                  </Link>
                  <h3>Complete company profile</h3>
                </div>
                <form
                  onSubmit={formSubmit}
                  className="form-contol complete-profile-form"
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Company name"
                      id="company_name"
                      name="company_name"
                      minLength="2"
                      maxLength="40"
                      error={Error.company_name ? true : false}
                      onChange={InputEvent}
                      value={data.company_name}
                      required
                    />
                    {Error.company_name && <small>{Error.company_name}</small>}

                    <label htmlFor="company_name" />
                  </div>

                  <div className="position-relative">
                    <input
                      type="number"
                      placeholder="Phone number"
                      id="phone"
                      name="phone"
                      onChange={InputEvent}
                      value={data.phone}
                      error={Error.phone ? true : false}
                      required
                    />
                    {Error.phone && <small>{Error.phone}</small>}
                    <label htmlFor="phone" />
                  </div>
                  <div className="position-relative">
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          {/* <p>Latitude: {coordinates.lat}</p>
                        <p>Longitude: {coordinates.lng}</p> */}
                          <input
                            {...getInputProps({
                              className: "my-input",
                            })}
                            id="c_location"
                            name="c_location"
                            placeholder="Location"
                            required
                          />
                          <label htmlFor="c_location" />
                          <div className="autocomplete-dropdown-container">
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#e25828"
                                  : "#fff",
                              };
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="row">
                    <div className="col-6 ">
                      <div className="position-relative file-container">
                      <input
                        ref={ref}
                          type="file"
                         name="ficafile"
                          onChange={(event) => handleFicaImage(event) }
                          // id="select_files"
                        />
                        <label htmlFor="select_files" className>
                          Upload FICA docs
                        </label>
                        <span className="file_name" />

                        {showImage1 ? (
                      <div class=" popup-gallery">
                        <a target='_blank' href={image1}>
                          Upload FICA docs View
                          {/* <img src={image1} alt="File Preview" />{" "} */}
                          <i onClick={delete1} class="delete-icon"></i>
                        </a>
                      </div>
                    ) : null}
                       
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="position-relative file-container">
                      <input
                          type="file"
                          placeholder
                          title
                          onChange={(event) => handleLicense(event) }
                          ref={ref1}
                         // id="select_files"
                        />
                        <label htmlFor="select_files" className>
                          Upload License
                        </label>
                        <span className="file_name" />
                        <div className="col-12">
                       {showImage2 ? (
                      <div class="">
                        <a target="_blank" href={image2} >
                            Upload License docs View  
                        </a>
                      </div>
                    ) : null} 
                    </div>
                        {/* <i class="delete-icon"></i>
                                  <i class="open-eye"></i>
                                  <div class="preview-image">
                                      <img src="assets/images/sample-doc (1).png" alt="">
                                  </div> */}
                        {/* <div class="uploads-preview popup-gallery">
                                      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                                      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                                      <a href="assets/images/shipper-background (1).png"><img src="assets/images/shipper-background (1).png" alt=""> <i class="delete-icon"></i></a>
                                  </div> */}
                      </div>
                    </div>
                     <div className="col-12">
                    { (progress == null) ? '' : 
                    
                    <div style={{width: "100%"}} class="progress" >
                      <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${progress}`,backgroundColor:'#e25828'}} role="progressbar"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      {progress}
                      </div>
                    </div>
                    }
                    </div>
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Password"
                      id="password"
                      minLength="4"
                      name="password"
                      onChange={InputEvent}
                      error={Error.password ? true : false}
                      value={data.password}
                      required
                    />
                    {Error.password && <small>{Error.password}</small>}
                    <label htmlFor="password" />
                  </div>
                  <div className="position-relative">
                    <input
                      type="password"
                      placeholder="Confirm password"
                      id="c_password"
                      name="c_password"
                      minLength="4"
                      onChange={InputEvent}
                      error={Error.c_password ? true : false}
                      value={data.c_password}
                      required
                    />
                    {Error.c_password && <small>{Error.c_password}</small>}
                    <label htmlFor="c_password" />
                  </div>
                  <div className="signed-in accept-conditions">
                    <div className="checkbox-container">
                      <input type="checkbox" name id="accept_tc" />
                      <span className="checkmark" />
                      <label htmlFor="accept_tc">
                        Accept <a href="#">terms &amp; conditions</a>
                      </label>
                    </div>
                  </div>
                  <div className="submit-button">
                    <button type="submit" className="btn large grey">
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
