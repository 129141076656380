import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import http from "./../../context/http";
import instance from "../../context/fetchclient";
import TokenStore from "../../localstore/TokenStore";
// import io from "./../../../";
// import store from "store";
// React-Toastify
// import { ToastContainer, toast } from "react-toastify";
//import { uploadFile } from "react-s3";
const customId = "custom-id-yes";

function DashboardIcons({UserToken,UserRole}) {
  const navigate = useNavigate();
  const [getCount,setCount] = useState([]);

//   // console.log(store)

  useEffect(() => {
    // console.log('AccessToken',TokenStore.getLocalAccessToken());
    // console.log('RefeshToken',TokenStore.getLocalRefreshToken());
    // console.log('$%^^',{UserRole,UserToken})
    getDashboardCount(UserToken,UserRole);
  },[])


  function getDashboardCount(token,role){
    // console.log('add');

    instance.get('/dashboard').then((response)=> {
      // console.log('Dashboard',response);
      let Counts = response.data.data; 
      let Data = [];
      switch (role) {
        case 'company':
          Data = [
            { count:Counts.applied_jobs,name:'Applied jobs',icon:'applied-list' },
            { count:Counts.ready_to_pick_jobs,name:'Ready to pick',icon:'truck' },
            { count:Counts.on_route_jobs,name:'On route',icon:'on-route' },
            { count:Counts.completed_jobs,name:'Completed',icon:'check-completed' },
          ]
        break;
      
        case 'owner':
          Data = [
            { count:Counts.applied_jobs,name:'Applied jobs',icon:'applied-list' },
            { count:Counts.ready_to_pick_jobs,name:'Ready to pick',icon:'truck' },
            { count:Counts.on_route_jobs,name:'On route',icon:'on-route' },
            { count:Counts.completed_jobs,name:'Completed',icon:'check-completed' },
          ]
        break;
      
        case 'shipper':
          Data = [
            { count:Counts.active_jobs,name:'Active jobs',icon:'applied-list' },
            { count:Counts.ready_to_pick_jobs,name:'Ready to pick',icon:'truck' },
            { count:Counts.on_route_jobs,name:'On route',icon:'on-route' },
            { count:Counts.completed_jobs,name:'Completed',icon:'check-completed' },
          ]
        break;
    
     
      }
      setCount(Data);
    }).catch((error) => {
      alert(error);
        console.log('error',error);
    })

  }


  return (
    <section className="quick-checks">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="row quick-view-cards">
            { getCount.map((card) => { 
                return ( 
                <div className="col-6 col-md-3">
                <div className="card">
                  <i className={card.icon} />
                  <hr />
                  <h3> {card.count}</h3>
                  <h5> {card.name}</h5>
                </div>
              </div>)

              }) }
           
            {/* <div className="col-6 col-md-3">
              <div className="card">
                <i className="truck" />
                <hr />
                <h3>12</h3>
                <h5>Ready to pick</h5>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="card">
                <i className="on-route" />
                <hr />
                <h3>5</h3>
                <h5>On route</h5>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="card">
                <i className="check-completed" />
                <hr />
                <h3>76</h3>
                <h5>Completed</h5>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default DashboardIcons;
