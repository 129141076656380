import { Link } from "react-router-dom";
function App() {
  return (
<>    <section className="auth">
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-12 col-md-7">
          <div className="form-container enter-otp">
            <div className="title">
              <h3>Continue as</h3>
              <Link to="/">
              <button className="btn small back-button"><i className="back-cheveron" /></button>
          </Link>  
          </div>
            <div className="roles">
              <ul>
                <li>
                  <Link to="/CompleteShipperProfile" className="btn role-item role-shipper">
                    <h4>Shipper</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
                  </Link>
                </li>
                <li>
                  <Link to="/CompleteTransporterProfile" className="btn role-item role-transporter">
                    <h4>Transportation Company</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
                  </Link>
                </li>
                <li>
                  <Link to="/CompleteOwnerProfile" className="btn role-item role-v-owner">
                    <h4>Vehicle Owner</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  );
}
export default App;
