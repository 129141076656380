import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import TokenStore from "../../../localstore/TokenStore";
import store from "store";

import { ToastContainer, toast } from "react-toastify";
import instance from "../../../context/fetchclient";
import Loader from "../loader";


const customId = "custom-id-yes";

function UpdateRef({Apidata}) {
  const navigate = useNavigate();
  const [getCount,setCount] = useState([]);
  const [isloading,setloading] = useState(false);
  const [Error, setError] = useState({});
  const [getRefData, setRefData] = useState({
    company_name_1: Apidata.references[0].company_name,
    description_1: Apidata.references[0].description,
    phone_number_1: Apidata.references[0].phone,
    company_name_2: Apidata.references[1].company_name,
    description_2: Apidata.references[1].description,
    phone_number_2: Apidata.references[1].phone,
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setRefData(prevState => ({ ...prevState, [name]: value }));
  } 

//   // console.log(store)

  useEffect(() => {
    console.log('AccessToken',TokenStore.getLocalAccessToken());
    console.log('RefeshToken',TokenStore.getLocalRefreshToken());
  
  },[Apidata])





  const handleUpdateRef = (e) => {

    e.preventDefault();
    setError({});
    let errors = {};

    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!getRefData.phone_number_1) {
      errors.phone_number_1 = "This field is required";
    } else if (!re.test(getRefData.phone_number_1)) {
      errors.phone_number_1 = "Invalid phone format";
    }

    if (!getRefData.company_name_1) {
      errors.company_name_1 = "This field is required";
    } else if (getRefData.company_name_1.length > 40) {
      errors.company_name_1 = "Company name must be less than 40 characters";
    }

    if (!getRefData.phone_number_2) {
      errors.phone_number_2 = "This field is required";
    } else if (!re.test(getRefData.phone_number_2)) {
      errors.phone_number_2 = "Invalid phone format";
    }

    if (!getRefData.company_name_2) {
      errors.company_name_2 = "This field is required";
    } else if (getRefData.company_name_2.length > 40) {
      errors.company_name_2 = "Company name must be less than 40 characters";
    }

    if (
      errors.phone_number_1 ||
      errors.phone_number_2 ||
      errors.company_name_1 ||
      errors.company_name_2
    ) {
      setError(errors);
    } else {
      let refShipperData = {
        references: [
          {
            company_name: getRefData.company_name_1,
            description: getRefData.description_1,
            phone: getRefData.phone_number_1,
          },
          {
            company_name: getRefData.company_name_2,
            description: getRefData.description_2,
            phone: getRefData.phone_number_2,
          },
        ],
      };

      let ship_access_token = store.get("shipper_access_token");
      // console.log("access_token", ship_access_token);
    //   axios({
    //     method: "post",
    //     url: `${io.baseURL}/api/v1/shipper/update-references`,
    //     data: refShipperData,
    //     headers: {
    //       Authorization: `Bearer ${ship_access_token}`,
    //     },
    //   })
    setloading(true);
      console.log(refShipperData);

      
  

    instance.post(`/${(Apidata.role == 1) ? 'shipper': 'company'}/update-references`,refShipperData)
        .then(function (response) {
    setloading(false);

          if (response.data.type === "success") {
            store.remove("shipper");
            store.set("shipper", response.data.data);
            toast.info(response.data.message, {
              toastId: customId,
            });
          } else {
            toast.info(response.data.message, {
              toastId: customId,
            });
          }
        })
        .catch(function (error) {
    setloading(false);

          toast.error(error.response.data.message, {
            toastId: customId,
          });
        });
    }
  };

  return (<> 
  <Loader status={isloading} ></Loader>
     <form onSubmit={handleUpdateRef} className="form-contol edit-references-form" >
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <h5>Reference 1</h5>
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Company name"
                                onChange={handleChangeInput}
                                id="company_name_1"
                                minLength="2"
                                maxLength="40"
                                required
                                value={getRefData.company_name_1}
                                error={Error.company_name_1 ? true : false}
                                name="company_name_1"
                                // defaultValue="Jason silos limited"
                              />
                              {Error.company_name_1 && (
                                <small>{Error.company_name_1}</small>
                              )}
                              <label htmlFor="company_name_1" />
                            </div>
                            <div className="position-relative">
                              <input
                                type="number"
                                placeholder="Contact number"
                                name="phone_number_1"
                                value={getRefData.phone_number_1}
                                minLength="10"
                                onChange={handleChangeInput}
                                required
                                error={Error.phone_number_1 ? true : false}
                                id="phone_number_1"
                                //defaultValue={5463867764}
                              />
                              {Error.phone_number_1 && (
                                <small>{Error.phone_number_1}</small>
                              )}
                              <label htmlFor="phone_number_1" />
                            </div>
                            <div className="position-relative">
                              <textarea
                                name="description_1"
                                id="description_1"
                                required
                                value={getRefData.description_1}
                                placeholder="Description"
                                onChange={handleChangeInput}
                                //defaultValue={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat"}
                              />
                              <label htmlFor="description_1" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <h5>Reference 2</h5>
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Company name"
                                id="company_name_2"
                                name="company_name_2"
                                required
                                minLength="2"
                                error={Error.company_name_2 ? true : false}
                                maxLength="40"
                                value={getRefData.company_name_2}
                                onChange={handleChangeInput}
                                //defaultValue="Rice stores"
                              />
                              {Error.company_name_2 && (
                                <small>{Error.company_name_2}</small>
                              )}
                              <label htmlFor="company_name_2" />
                            </div>

                            <div className="position-relative">
                              <input
                                type="number"
                                placeholder="Contact number"
                                name="phone_number_2"
                                minLength="10"
                                required
                                error={Error.phone_number_2 ? true : false}
                                onChange={handleChangeInput}
                                value={getRefData.phone_number_2}
                                id="phone_number_2"
                                //defaultValue={5463867764}
                              />
                              {Error.phone_number_2 && (
                                <small>{Error.phone_number_2}</small>
                              )}

                              <label htmlFor="phone_number_2" />
                            </div>
                            <div className="position-relative">
                              <textarea
                                name="description_2"
                                id="description_2"
                                value={getRefData.description_2}
                                required
                                placeholder="Description"
                                onChange={handleChangeInput}
                              />
                              <label htmlFor="description_2" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="submit-button text-center">
                              <button type="submit" className="btn large grey">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
    </>);
}

export default UpdateRef;

