import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Footer } from "../../partials/Public/Footer";
import { Header } from "../../partials/Public/header";
function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
    <Header></Header>


    {/* Vehicle owner features section */}
    <section className=" vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <div className="row features">
              <div className="col-12">
                <div className="heading  d-flex align-items-center justify-content-center">
                 
                 <u>
                    <h3>Mi-Load Copyright Policy \Notification of Copyright Infringement</h3>
                 </u>
                  

                </div>
              </div>
              <div className="col-12 col-md-12">
              <p>Mi-Load respects the intellectual property rights of others and expects its users to do the same.</p>
              <p>It is Mi-Load’s policy, in appropriate circumstances and at its discretion, to disable and/or delete the accounts of users who persistently infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.</p>
              <p>In accordance with the COPYRIGHT ACT, 1978 (ACT NO. 98 OF 1978, AS AMENDED UP TO COPYRIGHT AMENDMENT ACT 2002), the text of which may be found on the South African government website https://www.gov.za/ .  Mi-Load will respond expeditiously to claims of copyright infringement committed using the Mi-Load website (the "Site") that are reported to Mi-Load Designated Copyright Agent.</p>
              <p>Materials featured on this site may be reproduced free of charge in any format or media without requiring specific permission. This is subject to the material being reproduced accurately and not being used in a derogatory manner or in a misleading context. Where the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to produce this material does not extend to any material on this site, which is explicitly identified as being the copyright of a third party. Authorization to reproduce such material must be obtained from the copyright holders concerned</p>
              <h6>DMCA Notice of Alleged Infringement ("Notice") ("Notice")</h6>
              <p>Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed.</p>
              <p>Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site where such material may be found, and  the reference or link, to the material or activity that you claim to be infringing, that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate that reference or link, including at a minimum, if applicable, the URL of the link shown on the Site where such reference or link may be found.</p>
              <p>Provide your mailing address, telephone number, and, if available, email address. Include both of the following statements in the body of the Notice:</p>
              <p>"I hereby state that I have a good faith belief that the disputed use of the copyrighted material or reference or link to such material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)."</p>
              <p>"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed. "Provide your full legal name and your electronic or physical signature.</p>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
    <Footer></Footer>
  </div>
  
  );
}

export default App;
