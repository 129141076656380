import { Link } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import http from "../../../../context/http";
import store from "store";
import TrashOrangeIcon from './../../../../layout-assets/icons/trash-orange.svg';
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import {Storage} from 'aws-amplify';
import ImageLoad from "../../../partials/ImageLoad";
import instance from "../../../../context/fetchclient";
import Loader from "../../../partials/loader";
import TruckObject from './../compant-transporter/truckobj.json';
const customId = "custom-id-yes";


function App() {
  const [isloading, setloading] = useState(true);
  const [Error, setError] = useState({});
  let location = useLocation();

  const [data, setData] = useState({
    type: "",
    manufacturer: "",
    truck_model: "",
    truck_year: "",
    truck_registration_num: "",
    truck_registration_doc: "",
    driver_name: "",
    driver_liscense_num: "",
    driver_liscense_doc: "",
    driver_contact: "",
    email: "",
    password1: "",
    password2: "",
    trailer: [
      {
        model: "",
        model_reg_num: "",
        model_doc_link: [  ],
      },
    ],
  });


  const navigate = useNavigate();
  const [getRefData, setRefData] = useState({});
  const [getTypeItems, setTypeItems] = useState([]);
  const [getManufacturerItems, setManufacturerItems] = useState([]);
  const [image3, setImage3] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image1, setImage1] = useState(null);
  const [showImage1, setShowImage1] = React.useState(false);
  const [showImage2, setShowImage2] = React.useState(false);
  const [showImage3, setShowImage3] = React.useState(false);
  const [showToken, setToken] = React.useState("");
  const [getTruckDatails,setTruckDatails] = React.useState(TruckObject)
  const [images, setImage] = useState({truck_registration_doc:'',driver_liscense_doc:''});
  const [rowsData, setRowsData] = useState([{model: "",model_reg_num: "",model_doc_link: [],trailer_id : "",}]);
  const [progress, setProgress] = useState({truck_registration_doc:'',driver_liscense_doc:'',trailerdoc:null});
  const [getDriverDetails,setDriverDetails] = React.useState({"driver_email": "","driver_password": ""});


  const ref = useRef(null);
  const reftruck = useRef(null);
  
  const delete1 = () => {
        setImage1("");
        setShowImage1(false);
      };
  const delete2 = () => {
      setImage2("");
      setShowImage2(false);
    };
  const delete3 = () => {
      setImage3("");
      setShowImage3(false);
    };
  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    let  TruckId = null
    if (location.search.split("?")[1]) {
      TruckId = location.search.split("?")[1];
    }

    // console.log(TruckId);
    TruckDatails(TruckId);
    // GetDriverDetails(TruckId);
    let trans_access_token = store.get("trans-comapny");
 
    if (trans_access_token !== undefined) {
      setToken(trans_access_token.access_token);
    }else{

    }
   
    getcompanyType();
    getCompanyManufacturer();
  }, []);



  const TruckDatails = (TruckId) => {
    instance.get(`/company/truck-details?truck_id=${TruckId}`)
      .then(function (response) {

        GetDriverDetails(response.data.data.driver.driver_id);
        setTruckDatails(response.data.data);
        SetApiDataState(response.data.data);
      })
      .catch(e => console.log(e))
  }

  const GetDriverDetails = (DriverId) => {
    instance.post(`/company/driver/credentials`,{driver_id:DriverId})
      .then((response) => { 
        setloading(false);
        setDriverDetails(response.data.data);
        setData((preVal) => { return { ...preVal,email: response.data.data.driver_email,};});
        console.log('Driver Details ', response.data)
       })

      .catch((err) => { console.log(err)
        setloading(false);
      
      })
  }

  const SetApiDataState  = (ApiData) => {
 
    let Trailer =  ApiData.trailers.map((value) => {
      return {
        model:value.model,
        model_reg_num:value.registration_number,
        model_doc_link:[value.trailer_documents[0].document_link],
        trailer_id : value.trailer_id
      }
    })

    setRowsData(Trailer);
    setImage({truck_registration_doc:ApiData.TruckDocuments[0].document_link,driver_liscense_doc:ApiData.driver.company_driver_details.license_doc});
    setData({
      type: ApiData.truck_type_detail.type_id,
      manufacturer: ApiData.truck_manufacturer.manufacturer_id,
      truck_model: ApiData.model,
      truck_year: ApiData.year,
      truck_registration_num: ApiData.registration_number,
      truck_registration_doc:ApiData.TruckDocuments[0].document_link,
      driver_name: ApiData.driver.name,
      driver_liscense_num: ApiData.driver.company_driver_details.license_num,
      driver_liscense_doc: ApiData.driver.company_driver_details.license_doc,
      driver_contact: ApiData.driver.phone,
      email: getDriverDetails.driver_email,
      password1: "",
      password2: "",
      trailer: [
        {
          model: "",
          model_reg_num: "",
          model_doc_link: [  ],
        },
      ],
    })
    
  }


  function displayImage(event) {
    if (event.target.files && event.target.files[0]) {
      return URL.createObjectURL(event.target.files[0]);
    }
  }
  const onImageChange1 = (event) => {
    setImage1(displayImage(event));
    setShowImage1(true);
  };
  const onImageChange2 = (event) => {
    setImage2(displayImage(event));
    setShowImage2(true);
  };
  const onImageChange3 = (event) => {
    setImage3(displayImage(event));
    setShowImage3(true);
  };


  const addTableRows = () => {
    const rowsInput = {
      model: "",
      model_reg_num: "",
      model_doc_link: [],
      trailer_id:''
    };

    // const process

    setRowsData([...rowsData, rowsInput]);
   
  };

  const deleteTableRows = (e,key=1) => {
    console.log(key);
    const rows = [...rowsData];
    console.log('before',rows);
    if(rows.length == 1){

    }else{
      rows.splice(key, 1);
    }
   
    console.log('after',rows);
    setRowsData(rows);
  };

const  handleTrailer = (e, index) => {
    // console.log(index,e.target.value);
    const Trailers = [...rowsData];
    Trailers[index][e.target.name] = e.target.value;
    setRowsData(Trailers);
  }

  const handleChangeImage = (e) => {
    // console.log('reftruck',reftruck.current.files[0].name);
    console.log('event',e.target.files[0]);
    const timestamp = new Date();
    const filename =  timestamp + e.target.files[0].name;
    Storage.put(filename, e.target.files[0],{}).then(resp => {
      console.log(e.target.name, resp.key);
      setImage(prevState => ({ ...prevState, [e.target.name]: resp.key }));
      }).catch(err => {
        console.log(err);
      });

  }

  const  handleTrailerImage = (e, index) => {
    // console.log(index,e.target.value);
    const Trailers = [...rowsData];
    // Trailers[index][e.target.name] = e.target.value;
    // setRowsData(Trailers);
    const timestamp = new Date();
    const filename =  timestamp + e.target.files[0].name;
    Storage.put(filename, e.target.files[0], {
        progressCallback: (progres) => {
          // const Process = progress;
          // Process.trailerdoc = Math.round((progres.loaded / progres.total) * 100) + "%";
          // setProgress(Process);
          
          // setProgress(Math.round((progress.loaded / progress.total) * 100) + "%");
          
          // setTimeout(() => { 
          //   const Processa = progress;
          //   Processa.trailerdoc = '';
          //   setProgress(Processa);
          //  }, 2000);
        }
      }).then(resp => {
        Trailers[index][e.target.name] = [resp.key];
        // Trailers[index].image_progress = null;
        console.log(resp.key,Trailers[index]);
        setRowsData(Trailers);

      }).catch(err => {// console.log(err);
      });
  }


  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...rowsData];
    rows[idx] = {
        [name]: value,
    };
    setRowsData({
        rows,
    });
};


  function getcompanyType() {
    axios({
      method: "get",
      url: `${io.baseURL}/api/v1/truck-type`,
    })
      .then(function (response) {
        if (response.data.type === "success") {
          setTypeItems({ data: response.data.data });
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // console.log("error", error);
      });
  }

  function getCompanyManufacturer() {
    axios({
      method: "get",
      url: `${io.baseURL}/api/v1/manufacturers`,
    })
      .then(function (response) {
        if (response.data.type === "success") {
          setManufacturerItems({ data: response.data.data });
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // console.log("error", error);
      });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let newDataObj;
    setError({});
    let errors = {};
   

   
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!data.driver_contact) {
      errors.driver_contact = "This field is required";
    } else if (!re.test(data.driver_contact)) {
      errors.driver_contact = "Invalid phone format";
    }

    if (!data.truck_year) {
      errors.truck_year = "This field is required";
    } else if (data.truck_year.length > 4) {
      errors.truck_year = "Truck year must be 4 digits";
    }

    if (!data.driver_name) {
      errors.driver_name = "This field is required";
    } else if (data.driver_name.length > 40) {
      errors.driver_name = "Driver name must be less than 40 characters";
    }
    
  

    if (
     
      errors.truck_year ||
      errors.driver_name ||
      errors.driver_contact ||
      errors.model
    ) {
      setError(errors);
    } else {


      // console.log('submit',data);



      let dataObj = {
        truck_type: data.type,
        truck_id: getTruckDatails.truck_id,
        truck_make: data.manufacturer,
        truck_model: data.truck_model,
        truck_year: data.truck_year,
        truck_registration_num: data.truck_registration_num,
        truck_registration_doc: images.truck_registration_doc,
        driver_name: data.driver_name,
        driver_liscense_num: data.driver_liscense_num,
        driver_liscense_doc:images.driver_liscense_doc,
        driver_contact: data.driver_contact,
        email: data.email,
        trailer: rowsData,
      };

      console.log('Submit Object --- Data ',dataObj);

      
      
      // axios({
      //   method: "post",
      //   url: `${io.baseURL}/api/v1/company/truck/add`,
      //   data: dataObj,
      //   headers: {
      //     Authorization: `Bearer ${showToken}`,
      //   },
      // })
      setloading(true);
      instance.post('/company/edit-truck',dataObj)
        .then(function (response) {
          setloading(false);
          if (response.data.type === "success") {
            toast.info(response.data.message, {
              toastId: customId,
            });
            
            // navigate("/TransporterDashboard");

          } else {
            toast.info(response.data.message, {
              toastId: customId,
            });
          }
        })
        .catch(function (error) {
      setloading(false);

          toast.error(error.response.data.message, {
            toastId: customId,
          });
          // console.log(error.response.data.message);
          // // console.log(error.response.status);
          // // console.log(error.response.headers);
          // console.log("error", error);
        }); 
    }
  };

  return (
    <div>
      <Loader status={isloading} ></Loader>
      {/* Hero section */}
      <section className="add-truck-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Link to="/TransporterDashboard">
                {" "}
                <button className="btn small back-button">
                  <i className="back-cheveron pr-2" />
                  Back to dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Shipper features section */}
      <section className="add-truck">
        <div className="container">
          <div className="row content justify-content-center">
            <div className="col-12 col-md-11">
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 text-right illustration">
                  <img src={ process.env.PUBLIC_URL + "assets/images/trucks-illustration (1).png"} alt />
                </div>
                <div className="col-12">
                  <h4>Update Truck</h4>
                  <small> Truck Id : {getTruckDatails.truck_number}  </small>
                </div>
                <div className="col-12">
                  <form onSubmit={formSubmit} className="add-truck-form">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <select className="select-box" onChange={InputEvent}value={data.type}
                            name="type"
                            id="type"
                            required
                          >
                            <option value="">Type</option>
                            {getTypeItems.data !== undefined
                              ? getTypeItems.data.map((el, key) => (
                                  <option key={key} value={el.type_id}>
                                    {el.name}
                                  </option>
                                ))
                              : "No Record Found"}
                          </select>
                          <label htmlFor="select-box" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <select
                            className="select-box"
                            onChange={InputEvent}
                            value={data.manufacturer}
                            name="manufacturer"
                            id="manufacturer"
                            required
                          >
                            <option value={0}>Make</option>
                            {getManufacturerItems.data !== undefined
                              ? getManufacturerItems.data.map((el, key) => (
                                  <option key={key} value={el.manufacturer_id}>
                                    {el.name}
                                  </option>
                                ))
                              : "No Record Found"}
                          </select>
                          <label htmlFor="select-box" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            onChange={InputEvent}
                            value={data.truck_model}
                            name="truck_model"
                            id="truck_model"
                            placeholder="Model"
                            required
                          />
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            onChange={InputEvent}
                            value={data.truck_year}
                            name="truck_year"
                            id="truck_year"
                            placeholder="Year"
                            error={Error.truck_year ? true : false}
                            required
                          />
                          {Error.truck_year && (
                            <small>{Error.truck_year}</small>
                          )}
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            placeholder="Registration number"
                            onChange={InputEvent}
                            value={data.truck_registration_num}
                            name="truck_registration_num"
                            id="truck_registration_num"
                            required
                          />
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative file-container">
                          <input
                            type="file"
                            placeholder
                            title
                            ref={reftruck}
                            onChange={(e)=>handleChangeImage(e)}
                            name="truck_registration_doc"
                            id="truck_registration_doc"
                            required={(images.truck_registration_doc == '') ? true : false}
                          />
                          <label htmlFor="select_files">Upload documents</label>
                          <div className="preview-image">

                  
                         {(images.truck_registration_doc != '') ?  <> {images.truck_registration_doc } <b> <ImageLoad ImageId={images.truck_registration_doc} /> </b></>: ''}

                    
                        </div>
                          <span className="file_name small"  >  

                              {/* {(images.truck_registration_doc != '') ?  <> {images.truck_registration_doc } <b> <ImageLoad ImageId={images.truck_registration_doc} /> </b></>
                             : ''} */}
                           
                             </span>
                          {showImage1 ? (
                            <div class="uploads-preview popup-gallery">
                              <a href="#!">
                                <img src={image1} alt="preview image" />{" "}
                                <i onClick={delete1} class="delete-icon"></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 divider" />
                      <div className="col-12">
                        <h5 className="text-orange ">Driver details</h5>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            placeholder="Driver name"
                            onChange={InputEvent}
                            value={data.driver_name}
                            name="driver_name"
                            id="driver_name"
                            error={Error.driver_name ? true : false}
                            required
                          />
                          {Error.driver_name && (
                            <small>{Error.driver_name}</small>
                          )}
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            placeholder="License number"
                            onChange={InputEvent}
                            value={data.driver_liscense_num}
                            name="driver_liscense_num"
                            id="driver_liscense_num"
                            required
                          />
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="number"
                            placeholder="Contact number"
                            onChange={InputEvent}
                            value={data.driver_contact}
                            name="driver_contact"
                            id="driver_contact"
                            error={Error.driver_contact ? true : false}
                            required
                          />
                          {Error.driver_contact && (
                            <small>{Error.driver_contact}</small>
                          )}
                          <label htmlFor />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative file-container">
                          <input
                            type="file"
                            placeholder
                            title
                            ref={reftruck}
                            onChange={(e)=> handleChangeImage(e)}
                            required={(images.driver_liscense_doc == '') ? true : false}
                            name="driver_liscense_doc"
                            id="driver_liscense_doc"
                          />
                          <label htmlFor="select_files" className>
                            Upload license
                          </label>
                          <div className="preview-image">

                  
{(images.driver_liscense_doc != '') ?  <> {images.driver_liscense_doc } <b> <ImageLoad ImageId={images.driver_liscense_doc} /> </b></>: ''}


</div>
                          
                          {/* <i class="delete-icon"></i>
                                      <i class="open-eye"></i>
                                      <div class="preview-image">
                                          <img src="assets/images/sample-doc (1).png" alt=""/>
                                      </div>  */}
                          {showImage2 ? (
                            <div class="uploads-preview popup-gallery">
                              <a href="#!">
                                <img src={image2} alt="preview image" />{" "}
                                <i onClick={delete2} class="delete-icon"></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <h5>Driver login credentials</h5>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <input
                            type="email"
                            onChange={InputEvent}
                            value={data.email}
                            name="email"
                            id="email"
                            disabled
                            placeholder="Driver email"
                            required
                          />
                          <label htmlFor />
                        </div>
                      </div>
                    
                      <div className="col-12 divider mt-4" />
                      <div className="col-12">
                      <h5>  Trailers </h5>
                      </div>
                      {rowsData!= undefined? rowsData.map((item, idx) => (
                        <div className="col-12 col-md-6 p-0" key={idx + 1}>
                           <div className="container">
                          <div className="row">
                          <div className="col-6">
                              <h5 className="text-orange ">Trailer {idx + 1}</h5>
                            </div>
                            <div className="col-6 text-right p-3">
                              <span onClick={(e)=> deleteTableRows(e,idx)} >
                                <img height='15px'
                                  src={TrashOrangeIcon}
                                  alt
                                />
                              </span>
                              
                            </div>

                          </div>
                          </div>
                          <div className="col-12">
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Model"
                                value={item.model}
                                onChange={(e)=> handleTrailer(e,idx)}
                                name="model"
                                id="model"
                                error={Error.model ? true : false}
                                required
                              />
                              {Error.model && <small>{Error.model}</small>}
                              <label htmlFor />
                            </div>
                            <div className="position-relative">
                            <input
                              type="text"
                              placeholder="Registration number"
                              value={item.model_reg_num}
                              onChange={(e)=> handleTrailer(e,idx)}
                              name="model_reg_num"
                              id="model_reg_num"
                              required
                            />
                            <label htmlFor />
                          </div>
                          </div>
                          <div className="col-12">
                          <div className="position-relative file-container">
                            <input
                              type="file"
                              placeholder
                              title
                            required={(item.model_doc_link[0] == '') ? true : false}
                              
                              ref={ref}
                              onChange={(e)=> handleTrailerImage(e,idx)}
                              name="model_doc_link"
                              id={`model_doc_link-${idx}`}
                            />
                            <label htmlFor="select_files" className>
                              Upload documents
                            </label>
                            <div className="preview-image">

                                                
                              {(item.model_doc_link[0] != '') ?  <> {item.model_doc_link[0] } <b> <ImageLoad ImageId={item.model_doc_link[0]} /> </b></>: ''}


                              </div>
                                                          {/* <span className="file_name small"  >  
                              {(item.model_doc_link != '') ?  <> {item.model_doc_link } <b> (Upload 100%)</b></>
                             : ''} */}
                           
                             {/* </span> */}
                            {showImage3 ? (
                              <div class="uploads-preview popup-gallery">
                                <a href="#!">
                                  <img src={image3} alt="preview image" />{" "}
                                  <i onClick={delete3} class="delete-icon"></i>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        </div>
                      )):""} 

                      <div className="col-12 text-center">
                        <a
                          className="btn flat small add-trailer"
                           onClick={addTableRows}
                          // onChange={handleChange()}
                        >
                          <i className="plus">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/dashboard-icons/add-truck.svg"
                              }
                              alt
                            />
                          </i>
                          Add trailer
                        </a>
                      </div>
                      <div className="col-12 text-right">
                        <button type="submit" className="btn large grey">
                        Update Truck
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      </section>
      {/* Footer */}
      <footer className="after-footer">
        <div className="container after-footer">
          <small>© All rights reserved | Copyright 2022</small>
          <ul className="extra-links">
            <li>
              <a href="#">Privacy policy</a>
            </li>
            <li>
              <a href="#">Terms of service</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
export default App;
