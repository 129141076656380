import { Link } from "react-router-dom";
import React from "react";
// import Logo from "../../../../public/assets/images/mi-admin-logo(1).png";

function SideNavBar() {
  return (
    <>
          <header className="admin-header">
              <div className=" header-cont">
                <div className="logo"><img src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/mi-admin-logo (1).png"
                                        } alt="" /></div>
                <div className="options">
                  <a href="#">
                    <img src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/feather-bell.svg"
                      } alt="" />
                    </a>
                    <Link to="/logout">
                    <img src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/log-out.svg"
                      } alt="" />&nbsp;
                      Logout
                    </Link>
                </div>
              </div>
          </header>
          <div className="container-fluid admin-dash">
            <div className="admin-sidebar">
              <div className="top">
                <div className="logo">
                  <img src={
                    process.env.PUBLIC_URL +
                    "assets/images/mi-admin-logo (1).png"
                  } alt="" />
                </div>
                <div className="toggle">
                  <img src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/sidebar-toggle.svg"
                    } alt="" />
                </div>
              </div>
              <div className="side-menu">
                <ul>
                  <li className="main-item "> <Link className="nav-link" to="/Admin-Dashboard">
                      Dashboard
                    </Link></li>
                  <li className="main-item drop-down ">Shippers 
                  <img className="drop-down-arrow" src={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/arrow-down.svg"
                  } alt="" />
                    <ul className="drop-down-list">
                      <li className="drop-down-item active">All</li>
                      <li className="drop-down-item ">Verified</li>
                      <li className="drop-down-item">Pending</li>
                    </ul>
                  </li>
                  <li className="main-item">Transporters</li>
                  
                  <li className="main-item"> <Link
                      to="/admin"
                    >
                      Admin
                    </Link></li>
                </ul>
              </div>
            </div>
          </div>
    </>
  );
}

export default SideNavBar;
