import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Footer } from "../../partials/Public/Footer";
import { Header } from "../../partials/Public/header";
function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>

    <Header></Header>
    {/* Vehicle owner features section */}
    <section className=" vowner-features-section">
      <div className="container vowner-features">
        <div className="row">
          <div className="col-8 col-md-3 left-image">
              {/* <img
              src={process.env.PUBLIC_URL + "assets/images/v-owner (1).png"}
              alt=""
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <div className="row features">
              <div className="col-12">
                <div className="heading d-flex align-items-center justify-content-center">
                  <u><h3>Privacy policy</h3></u>
                </div>
              </div>
              <div className="col-12 col-md-12">
              <h5> 1. Introduction</h5>
              <p>Welcome to <strong> Mi-Load</strong>.
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
              <br />
              <br />
              <strong> Mi-Load</strong> (“us”, “we”, or “our”) operates www.mi-load.com website (including any affiliated sites), Mi-Load mobile application, and other services operated by us (hereafter collectively referred to as the <strong>“Services”</strong>)

              <br /><br />
              Our Privacy Policy governs your visit to www.mi-load.com <strong>website & mobile</strong> application which explains how we collect, secure, and disclose information that results from your use of our Service. This notice also controls Mi-Load data gathering practices in relation to its services. 
              <br /><br />
              We use your data to provide and improve our Service. By using our Service, you agree to the collection and use of information following this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
              </p>

              <h5>2. Definitions </h5>
              <p>Service means the www.mi-load.com website and mobile applications operated by Mi-Load.</p>
              <p>Device means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</p>
              <p>Personal Data is any information that relates to an identified or identifiable individual.</p>
              <p>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
              <p>Transporter & Owner driver means individuals or companies that offer motor vehicles used to transport commodities</p>
              <p>Driver mean individuals licensed to operate motor vehicles to transport commodities on behalf of the Transportation companies</p>
              <p>Shipper mean individuals who own commodities to be transported, or any user of the service that is identified as a shipper, consignor or consignee who</p>
              <p>Personal data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
              <p><strong>Permission data</strong> means the data to which the website or application will have access according to our policy.</p>
              <p>Usage data is data collected automatically either generated using our Service or from our Service infrastructure itself (for example, the duration of a page visit).</p>
              <p>Cookies are small files stored on your device (computer or mobile device).</p>
              <p>User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
              <p>You or your refers to User/s</p>
              <h5>3. Information Collection and Use</h5>
              <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
              <h5>4. Types of Data Collected Personal Data</h5>
              <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally, identifiable information may include, but is not limited to:</p>
              <ol type="i" >
                <li><p>Email address</p></li>
                <li>
                  <p>First name and last name</p>
                </li>
                <li>
                  <p>Phone number</p>
                </li>
                <li>
                  <p>Goods in-transit insurance</p>
                </li>
                <li>
                  <p>Usage Data</p>
                </li>
                <li>
                  <p>Address, Country, State, Province, ZIP/Postal code, City</p>
                </li>
                <li>
                  <p>Cookies and Usage Data</p>
                </li>
              </ol>
            <h5>5. Use</h5>
            <p>Mi-load will use information for the purpose of registration which allow users to use our Service.</p>
            <h5>6. Collection of Information </h5>
            <p>Mi-Load may collect Personal Data and Additional Usage Information about you from a variety of sources, including, for example:</p>

            <h6>Personal information</h6>
            <p>This consists of:</p>
            <ol type="I">
              <li><p>When a user creates or edits a Mi-Load account, we collect information for their profile. This may include their name, email, phone number, login name and password, address, profile picture, payment, or banking information (including payment verification information), driver's license, and other government-issued identification documents (which may indicate document numbers as well as birth date, gender, and photo). This also contains car or insurance information, emergency contact information, user settings, and proof of health or fitness to perform services via Mi-Load apps and websites.</p></li>
              <li>
                <p>This also includes gender and/or profession (where required for specific Mi-Load services or programs).</p>
              </li>
              <li>
                <p>Background check and identity verification: To verify the eligibility of users and provide services through the Mi-Load apps and websites, we may collect information such as but not limited to, driver’s license, truck license, goods in-transit insurance, CIPC documentation, copy of director Id’s, SARS documents etc. </p>
              </li>
              <li>
                <p>We gather the data supplied by users when they want to register to use the Services.</p>
              </li>
            </ol>
            <h6> Information Collected Automatically </h6>
            <p>The Organization may use Personal Information for the following purposes:</p>
            <p>The information collected using our services consists of:</p>
            <ol type="I">
              <li className="p" > Location data (drivers): We collect drivers precise or approximate location data for a variety of reasons, including to enable transportation of commodities, to enable delivery tracking and safety features, to prevent and detect fraud, and to comply with legal requirements. When the Mi-load app is running in the foreground (app open and on-screen) or background (app open but not on-screen) of a mobile device, Mi-Load collects this information. </li>
              <li className="p">We acquire such information from the devices of users who permit us to do so. (For details on how riders and delivery receivers can permit location data gathering, see "Choice and transparency" below.) Mi-Load collects such information from the time a driver or delivery is requested until it is completed (and may indicate such collection via an icon or notification on your device, depending on your device's operating system) and whenever the app is running in the foreground (app open and on-screen) of the user's device.</li>
              <li className="p">Recipients of deliveries can use the Mi-Load app without allowing Mi-Load to collect their precise location data. However, this may impact certain Mi-Load service functionalities.</li>
              <li className="p">We collect transaction information related to the use of our services, such as the type of services requested, commodity type, loading place, offloading place, agreed rate, date, time, distance travelled etc.</li>
            </ol>
            <h5>7. Use of Personal Data </h5>
            <p>The Organization may use Personal Information for the following purposes:</p>
            <ul>
              <li>
                <p>To provide and maintain our Service, including monitoring its utilization.</p>
              </li>
              <li>
                <p>To comply with the POPI act</p>
              </li>
              <li>
                <p>To manage Your Account entails managing Your registration as a Service user. The Personal Data You submit can grant You access to various Service features that are available to You as a registered user.</p>
              </li>
              <li>
                <p>For the performance of a contract: the creation, compliance, and execution of a load done through our Services or of any other deal with Us via the Service.</p>
              </li>
              <li>
                <p>To contact You: To contact You via email, telephone calls, SMS, or other equivalent means of electronic communication, such as push notifications from a mobile application, on updates or instructive communications pertaining to the features, products, or contracted services, including security upgrades, where required or reasonable for their implementation.</p>
              </li>
              <li>
                <p>Unless you have opted out of receiving such communications, we will send you updates, special offers, and general information about similar items, services, and events that we offer.</p>
              </li>
              <li>
                <p>To manage Your requests: To respond to and manage Your requests.</p>
              </li>
              <li>
                <p>We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all Our assets, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
              </li>
              <li>
                <p>We may also use Your information for data analysis, analyzing usage trends, determining the success of our promotional efforts, and evaluating and enhancing our Service, products, services, marketing, and your experience.</p>
              </li>
            </ul>
            <h5>8. Storage of Your Information</h5>
            <p>Mi-Load stores your Personal Data until you have deregistered or until you ask us to delete your Personal Data.</p>
            <h5>9. Usage Data</h5>
            <p>We may also collect information that your browser sends whenever you visit our Service or when you access our Service by or through any device (“Usage Data”).</p>
            <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When you access our Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>

           <h6>a. Communication Data </h6>
           <p>We enable users to communicate with each other and Mi-Load via Mi-Load mobile applications and websites. For instance, we enable drivers, as well as delivery personnel and delivery receivers, to call, text, and exchange other files (generally without disclosing their telephone numbers to each other). Mi-Load obtains information regarding calls, texts, and other communications in order to deliver this service, including the date, time, and content of these messages. Mi-Load may also utilize this information for customer support (including resolving disputes between customers), safety and security, service and product improvement, and analytics.</p>
           <h6>b. Location Data</h6>
           <p>We may use and store information about your location (“Location Data”). We use this data to provide our Service, to improve and customize our service.</p>
           <h6>c. Tracking Cookies Data </h6>
           <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.            </p>
            <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
            </p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <h6>d. Other Data</h6>
            <p>While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, citizenship, registration at the place of residence and actual address, and telephone number (work, mobile).</p>
            <h5>10. Retention of Data </h5>
            <p>We will retain your Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods. The Company maintains the specific records and retention policies and procedures so that the personal data is deleted after a reasonable time by complying with the retention criteria which is (a)The data will be kept if you are an active member of ours or if it is needed to provide our Services to you. (b)The data is retained for as long as it is needed to comply with the legal and contractual obligations.</p>
            <p>Following an account deletion request, Mi-Load deletes the user's account and data, unless they must be retained due to legal or regulatory requirements, for purposes of safety, security, and fraud prevention, or because of an outstanding credit or unresolved claim or dispute. Data is normally destroyed after 90 days of a deletion request, unless if retention is necessary for the foregoing reasons.</p>
            <h5>11. How We Share Your Data </h5>
            <p>We may not disclose personal information that we collect, or that you provide for personal use. If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.</p>
            <p>Your personal information may be shared in the following instances:</p>
            <ul>
              <li>
                <p>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service and to communicate with You.</p>
              </li>
              <li>
                <p>We may share or transfer Your personal information in conjunction with or during talks of any merger, sale of Firm assets, financing, or purchase of all or part of Our business by another company.</p>
              </li>
              <li>
                <p>With Affiliates: We may share Your information with Our affiliates, in which case those affiliates will be required to adhere to this Privacy Statement. Affiliates consist of Our parent company and any other subsidiaries, joint venture partners, or other businesses that We control or that are controlled jointly with Us.</p>
              </li>
              <li>
                <p>With business partners: We may share Your information with Our business partners in order to provide You with certain products, services, or promotions.</p>
              </li>
            </ul>
          <p>We may also disclose your Personal Data as follows:</p>
          <ul>
            <li><p>
              <strong>Third-Party Sites.  </strong>
              Mi-Load may provide your Personal Data to third parties that provide our Services to assist us with the Website and mobile applications and with achieving the Purposes. For example, these third parties may include providers of customer service, payment processing, email, and messaging support, hosting, management, maintenance, information analysis, or other services we may receive on an outsourced basis. We will endeavor to require that these third parties use your Personal Data only as necessary for such reasons. From Third-Party Information Providers. Mi-Load may also obtain Personal Data from third parties that have obtained or collected information about you and that have the right to provide that Personal Data to us. 
              </p></li>
              <li><p>
              <strong>Advertisers. </strong>
              From time-to-time, we engage advertising services to assist us in advertising our brand and Service. Those services may target advertisements on third-party websites based on cookies or other information indicating previous interaction with our Service.
              </p></li>
              <li><p>
              <strong>Law Enforcement and Safety.  </strong>
              Mi-Load may share your Personal Data with law enforcement or other government officials if it relates to a criminal investigation, alleged illegal activity, or regulatory enforcement. Mi-load may share your Personal Data as required or permitted by law, whether in response to subpoenas, court orders, other legal processes, or as we believe necessary to exercise or enforce our rights, including to defend against potential or actual legal claims brought against us. Mi-Load may disclose your Personal Data if we believe that such action is necessary to protect or defend the rights, property, interests, or safety of Mi-load, any of our users, or any other third parties or to act under urgent circumstances to protect the safety of Mi-Load or its employees or a member of the public. 
              </p></li>
              <li><p>
              <strong>Business partners of Mi-load.  </strong>
              For instance, if a user seeks a service through a partnership or promotional offer made by a third party, Mi-Load may share certain information with that third party. This may include other services, platforms, apps, or websites that integrate with our APIs; services; those with an API or service with which we integrate; or other Mi-Load business partners and their users in the context of promotions, contests, or specialized services.
             </p></li>
          </ul>
          <h5>12. Security of Data</h5>
          <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable and legal means to protect your Personal Data, we cannot guarantee its absolute security.</p>
          <p>We have several security measures in place to ensure the safety of your data, including the following:</p>
          <ul>
            <li>
              <p><strong>Firewalls:</strong> These network security systems help block unauthorized access.</p>
            </li>
            <li>
              <p><strong>SSL (Secure Socket Layer) Encryption:</strong> This encryption protects your information from being intercepted by creating a secure connection with your browser when you log in, enter information, and every interaction you make on our site.</p>
            </li>
            <li>
              <p><strong>Automatic Logout:</strong> We automatically log you out of your secure browsing session after a period of inactivity to protect you from third-party access to your information.</p>
            </li>
            <li>
              <p><strong>Encrypted Information:</strong>We encrypt your information before storing it in a database where no one can see your data.</p>
            </li>
          </ul>
          <p>In addition, we regularly perform “pen tests”, which are designed to find vulnerabilities that an attacker could exploit. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>

          <h5>13. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h5>
          <p>If you wish to be informed of what Personal Data, we hold about you and if you want it to be removed from our systems, please go to Contact Us section at our website.</p>
          <p>In certain circumstances, you have the following data protection rights:</p>
          <ol type="1" >
            <li>
              <p>The right to access, update, or delete the information we have on you.</p>
            </li>
            <li>
              <p>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
            </li>
            <li>
              <p>The right to object. You have the right to object to our processing of your Personal Data.</p>
            </li>
            <li>
              <p>The right of restriction. You have the right to request that we restrict the processing of your personal information.</p>
            </li>
            <li>
              <p>The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable, and commonly used format.</p>
            </li>
            <li>
              <p>The right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information.</p>
            </li>
          </ol>
          <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, that we may not be able to provide our Service without some necessary data.</p>

          <h6>1 .What personal information do we have about you. If you make this request, we will return to you:</h6>

          <ol type="1">
            <li> <p>The categories of personal information we have collected about you.</p> </li>
            <li> <p>The categories of sources from which we collect your personal information.</p> </li>
            <li> <p>The business or commercial purpose for collecting your personal information.</p> </li>
            <li> <p>The categories of third parties with whom we share personal information.</p> </li>
            <li><p>The specific pieces of personal information we have collected about you</p></li>
          </ol>
          <h5>14. Service Providers</h5>
          <p>We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide our Service on our behalf, perform our Service-related Services, or assist us in analyzing how our Service is used.</p>
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
          <h5>15 .Analytics</h5>
          <p>We may use certain third-party analytics services to improve the functionality, features, or delivery of our Service. We may also use these analytics services to record mouse clicks, mouse movements, scrolling activity, as well as any text that you type into our Service. </p>
          <h5>16. CI/CD tools</h5>
          <p>We may use third-party our Service Providers to automate the development process of our Service.</p>
          <h5>17. Behavioral Remarketing</h5>
          <p>We may use remarketing our Services to advertise on third-party websites to you after you visited our Service. We and our third-party vendors may use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
          <h5>18. Children’s Privacy</h5>
          <p>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
          <p>We do not knowingly collect personally identifiable information from children under 18. If you become aware that a child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
          <h5>19. Changes to This Privacy Policy</h5>
          <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the email address you provided to us or through a notice on the website’s home page. You are responsible for ensuring we have an up-to-date active and deliverable email address of yours. Also, ensure to periodically visit our website and check this privacy policy for any changes.</p>
          <h5>20. Cookies Policy</h5>
          <p>We collect information about you automatically when you visit the Website. The Website uses cookies to collect these details.</p>
          <p>A cookie is a small text file that is placed on your computer's hard drive by your web browser when you first visit the Website. The cookie allows us to identify your computer and find out details about your previous visit.</p>
          <h5>21.  We use cookies to:</h5>
          <ul>
            <li>
              <p>Detect if you have chosen to view the Website via mobile device or desktop machine or tablet.</p>
            </li>
            <li>
              <p>Gather statistics about how you use the Website, which web pages you visit as well as what other sites you visit before visiting the Website and after the Website.</p>
            </li>
            <li>
              <p>Some of the cookies are set by us, and some are set by third parties.</p>
            </li>
            <li>
              <p>By continuing to use this Website we will infer that you accept our cookies policy and give Mi-Load permission to continue serving your browser cookies for the above purposes. We reserve the right to edit, remove or add new cookies or similar technologies at any time and without notification. If you do not want to accept these terms simply discontinue using this Website.</p>
            </li>
            <li>
              <p>Most web browsers automatically accept cookies but, if you prefer, you can change your browser settings to disallow all or some cookies as well as view the cookies that are currently saved on your computer. To change your cookie settings, we strongly recommend that you refer to your Internet browser's manual or manufacturer for instructions on how to use this feature.</p>
            </li>
          </ul>
          <p>Please be aware that if you choose to delete, disable, or block some or all of the cookies used by this Website, then we cannot guarantee that all of the Website features will continue to function correctly.</p>

        <h5>22. Use of Your Information</h5>
        <p>By using this Website and mobile application, you agree in the entity that we may collect, hold, process and use your information (including personal information) to provide you with the Website and mobile application Services.</p>

          




          
            </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Footer */}
   <Footer></Footer>
  </div>
  
  );
}

export default App;
