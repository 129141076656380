import { Link } from "react-router-dom";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "../../../utils/axios";
import store from "store";
import SideNavBar from "../admin-navbar";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import FooterAdminBottom from "../admin-footer";
const customId = "custom-id-yes";

function App() {
  const [getData, setData] = useState({
    toekn: "",
  });
  const [getListData, setListData] = useState([]);
  const navigate = useNavigate();
  let shipperRes = store.get("SuperAdminLogin");
  const [deleteId, setDeleteId] = useState({});

  const [data, setBindData] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    country_code: "",
    role: 3,
  });
 const [openModal, setOpenModal] = useState(false);
  // const {isShowing, toggle} = useModal();

  const toggleModal = () => {
    setOpenModal(!openModal);
  }

  useEffect(() => {
    if (shipperRes) {
      getAdminDetails(shipperRes);
    }
  }, []);

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setBindData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  function getAdminDetails(shipperRes) {
    // console.log("##########--coming--#########",shipperRes);
    axios({
      method: "get",
      url: `${io.baseURL}/api/admin/get-allAdmin`,
      headers: {
        Authorization: `Bearer ${shipperRes.token}`,
      },
    })
      .then(function (response) {
        if (response.data.type === "success") {
          setListData(response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          // setTimeout(() => {
          //   navigate("/ShipperDashboard");
          // }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log("error", error);
      });
  }

  const formSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${io.baseURL}/api/admin/create-admin`,
      data: data,
      headers: {
        Authorization: `Bearer ${shipperRes.token}`,
      },
    })
      .then(function (response) {
        if (response.data.type === "success") {
          getAdminDetails(shipperRes);
          toast.info(response.data.message, {
            toastId: customId,
          });
          document.getElementById("deleteAdmin").modal('hide');
          // setTimeout(() => {
          //   navigate("/ShipperDashboard");
          // }, 800);
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log(error.response.data.message);
        // // console.log(error.response.status);
        // // console.log(error.response.headers);
        // console.log("error", error);
      });
  };

  const deleteAdmin = (e) => {
    e.preventDefault();
    // console.log("@@@@@@@@@@@@@",deleteId);
    axios({
      method: "delete",
      url: `${io.baseURL}/api/admin/delete-admin/${deleteId}`,
      headers: {
        Authorization: `Bearer ${shipperRes.token}`,
      },
    }).then(function (response) {
        if (response.data.type === "success") {
          getAdminDetails(shipperRes);
          toggleModal();
          //setListData(response.data.data);
          toast.info(response.data.message, {
            toastId: customId,
          });
          // setTimeout(() => {
          //   navigate("/ShipperDashboard");
          // }, 800);
         
        } else {
          toast.info(response.data.message, {
            toastId: customId,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          toastId: customId,
        });
        // console.log("error", error);
      });
  };

  const columns = [
    {
      name: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p variant="outlined">{tableMeta.rowIndex + 1} </p>;
        },
      },
    },
    {
      name: "uuid",
      options: {
          display: false,
          filter: false,
          sort: false,
      }
  },
    {
      name: "name",
      label: "Name",
      //  options: {
      //   filter: true,
      //   sort: true,
      //  }
    },
    {
      name: "email",
      label: "Email",
      //  options: {
      //   filter: true,
      //   sort: false,
      //  }
    },
    {
      name: "phone",
      label: "Phone",
      //  options: {
      //   filter: true,
      //   sort: false,
      //  }
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === true || value === false ? (
            value === true ? (
              <p
                style={{ color: "green", fontWeight: "bold" }}
                variant="outlined"
              >
                Active{" "}
              </p>
            ) : (
              <p
                style={{ color: "red", fontWeight: "bold" }}
                variant="outlined"
              >
                InActive{" "}
              </p>
            )
          ) : (
            <p style={{ fontWeight: "bold" }} variant="outlined">
              N/A
            </p>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Creation date",
      //  options: {
      //   filter: true,
      //   sort: false,
      //  }
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log("----------------------", tableMeta.rowData);
        
          return (
            <div className="container">
              <div className="d-flex justify-content-end">
                <div className="col-4">
                  <a
                    href="#!"
                    data-toggle="modal"
                    data-target="#deleteAdmin"
                    onClick={() => {setDeleteId(tableMeta.rowData[1]);}}
                  >
                    <i
                      style={{ color: "#1F3849" }}
                      className="delete-icon"
                      aria-hidden="true"
                      title="Delete"
                    ></i>
                  </a>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <div>
        <div>
          <SideNavBar />
          {/* Shipper features section */}
          <section className="admin-main-section sidebar-padding">
            <div className="container">
              <div className="row content">
                <div className="col-12">
                <div className="row">
                <button
                      className="btn small orange"
                      data-toggle="modal"
                      data-target="#AddAdmin"
                    >
                      Add Admin
                    </button>
                </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className=" trucks-list"
                      id="nav-trucks"
                      role="tabpanel"
                      aria-labelledby="nav-trucks-tab"
                    >
                      <MUIDataTable
                        title={"Admin List"}
                        data={getListData}
                        columns={columns}
                        // options={options}
                        options={{
                          filter: true,
                          filterType: "dropdown",
                          fixedHeader: false,
                          selectableRows: false,
                          fixedSelectColumn: true,
                          responsive: "scrollMaxHeight",
                          rowHove: true,
                          onDownload: (buildHead, buildBody, columns, data) => {
                            columns.pop();
                            columns.shift();
                            data.map((item) => {
                              item.data.shift();
                              item.data.pop();
                              return null;
                            });
                            return (
                              "\uFEFF" + buildHead(columns) + buildBody(data)
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer */}
          <FooterAdminBottom/>
        </div>
           {/* Add Admin modal */}
        <div
          className="modal fade"
          id="AddAdmin"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="AddAdminTitle"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="col-12">
                <div className="form-container">
                  <div className="modal-header">
                    <h5
                      className="modal-title underline"
                      id="exampleModalCenterTitle"
                    >
                      Add Admin
                    </h5>
                  </div>
                  <form
                    onSubmit={formSubmit}
                    className="form-contol login-form"
                  >
                    <div className="modal-body">
                      <div className="position-relative">
                        <label htmlFor="password">Email</label>
                        <input
                          type="email"
                          placeholder="Enter email"
                          onChange={InputEvent}
                          value={data.email}
                          required
                          name="email"
                          id="email"
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          placeholder="Enter password"
                          onChange={InputEvent}
                          value={data.password}
                          required
                          name="password"
                          id="password"
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="password">Name</label>
                        <input
                          type="text"
                          placeholder="Enter name"
                          onChange={InputEvent}
                          value={data.name}
                          required
                          name="name"
                          id="name"
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="password">Phone</label>
                        <input
                          type="number"
                          placeholder="Enter phone"
                          onChange={InputEvent}
                          value={data.phone}
                          required
                          name="phone"
                          id="phone"
                        />
                      </div>
                      <div className="position-relative">
                        <label htmlFor="password">Country Code</label>
                        <input
                          type="text"
                          placeholder="Enter country code"
                          onChange={InputEvent}
                          value={data.country_code}
                          required
                          name="country_code"
                          id="country_code"
                        />
                      </div>
                      {/* <div className="position-relative">
                        <label htmlFor="password">Role</label>
                        <input
                          type="text"
                          placeholder="Enter role"
                          onChange={InputEvent}
                          value={data.role}
                          required
                          name="role"
                          id="role"
                        />
                      </div> */}
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn inset-btn small">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn orange small"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
       {/* delete admin modal */}
        <div
          className="modal fade"
          id="deleteAdmin"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteAdminTitle"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="col-12">
                <div className="form-container">
                  <div className="modal-header">
                    <h5
                      className="modal-title underline"
                      id="exampleModalCenterTitle"
                    >
                      Are you sure you want to remove this item
                    </h5>
                  </div>
                  <form
                    onSubmit={deleteAdmin}
                    className="form-contol login-form"
                  >
                    <div className="modal-footer">
                      <button type="submit" className="btn inset-btn small">
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn orange small"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
